/*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import langReducer from './lang.slice';
import layersSlice from './layers.slice';
import newsSlice from './news.slice';
import toastSlice from './toast.slice';
import uiSettingsSlice from './uiSettings.slice';
import userSlice from './user.slice';
import weeksSlice from './weeks.slice';

export const store = configureStore({
  reducer: {
    lang: langReducer,
    layers: layersSlice,
    news: newsSlice,
    toast: toastSlice,
    uiSettings: uiSettingsSlice,
    user: userSlice,
    weeks: weeksSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
