/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { E_LEFT_PANEL, LEAFLET_GROUP_LAYERS } from '../constants/Enums';
import { AppContext, LAYER_ACTIONS } from '../context/app.context';
import { NewsArticles } from '../interfaces/User.interfaces';
import { getNews, openArticle, showArticlePlume } from '../redux/news.slice';
import { setLeftSidePanel } from '../redux/uiSettings.slice';
import { createGroupLayer } from '../utils/LayerConstructor.Groups';
import { loadNewsPlumes } from '../utils/Layers.utils';
import { seeNewsPlumeOnMap } from '../utils/Map.utils';

function useLayerPopulater() {
  const { mapView, updateLayers } = useContext(AppContext);
  const dispatch = useDispatch();
  const news = useSelector(getNews);
  useEffect(() => {
    if (mapView) {
      const basemapGroup = createGroupLayer(LEAFLET_GROUP_LAYERS.Basemap);
      updateLayers(LAYER_ACTIONS.ADD, basemapGroup);
    }
  }, [mapView, updateLayers]);

  useEffect(() => {
    if (mapView) {
      const newConcentrationGroup = createGroupLayer(LEAFLET_GROUP_LAYERS.Concentration);
      updateLayers(LAYER_ACTIONS.ADD, newConcentrationGroup);
    }
  }, [mapView, updateLayers]);

  useEffect(() => {
    const handlePinClick = (article: NewsArticles) => {
      dispatch(setLeftSidePanel(E_LEFT_PANEL.News));
      dispatch(showArticlePlume(article.id));
      dispatch(openArticle(article.id));
      seeNewsPlumeOnMap(mapView, article.latitude, article.longitude);
    };
    const loadLayers = async () => {
      const layers = await loadNewsPlumes(news, handlePinClick);
      const newNewsPlumesGroup = createGroupLayer(LEAFLET_GROUP_LAYERS.NewsPlumes, layers);
      updateLayers(LAYER_ACTIONS.ADD, newNewsPlumesGroup);
    };
    if (mapView && news.length > 0) {
      void loadLayers();
    }
  }, [dispatch, mapView, news, updateLayers]);
}
export default useLayerPopulater;
