/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isAfter, nextWednesday, previousWednesday } from 'date-fns';
import { DateRange, Week, WeekTimelineRange } from '../interfaces/Common.interfaces';
import { RootState } from './store';

interface LocalState {
  activeWeek: number | undefined;
  minWeek: number | undefined;
  maxWeek: number | undefined;
  timeline: WeekTimelineRange;
  weeks: Array<Week> | undefined;
}

const initialState: LocalState = {
  activeWeek: undefined,
  minWeek: undefined,
  maxWeek: undefined,
  timeline: {
    start: undefined,
    end: undefined,
  },
  weeks: undefined,
};

export const weeksSlice = createSlice({
  name: 'weeks',
  initialState,
  reducers: {
    initWeeks: (state, action: PayloadAction<Array<Week>>) => {
      return {
        activeWeek: action.payload.length - 1,
        minWeek: 0,
        maxWeek: action.payload.length - 1,
        timeline: {
          start: 0,
          end: action.payload.length - 1,
        },
        weeks: action.payload,
      };
    },
    setActiveWeek: (state, action: PayloadAction<number>) => {
      if (
        state.minWeek !== undefined &&
        action.payload >= state.minWeek &&
        state.maxWeek !== undefined &&
        action.payload <= state.maxWeek
      ) {
        state.activeWeek = action.payload;
      }
    },
    setTimeline: (state, action: PayloadAction<DateRange>) => {
      const { start, end } = action.payload;
      if (state.weeks && start && end) {
        const startWeek = state.weeks.find((week) => isAfter(new Date(week.captured), start));
        const endWeek = state.weeks.find((week) => isAfter(new Date(week.captured), end));
        if (startWeek && endWeek) {
          state.timeline = {
            start: startWeek.weekIndex,
            end: endWeek.weekIndex,
          };
          state.activeWeek = endWeek.weekIndex;
        }
      }
    },
    next: (state) => {
      if (state.activeWeek !== undefined && state.activeWeek !== state.maxWeek) {
        state.activeWeek += 1;
      }
    },
    previous: (state) => {
      if (state.activeWeek !== undefined && state.activeWeek !== state.minWeek) {
        state.activeWeek -= 1;
      }
    },
  },
});

export const { initWeeks, setActiveWeek, setTimeline, next, previous } = weeksSlice.actions;

export const getWeeks = (state: RootState) => state.weeks.weeks;
export const getWeekExtent = (state: RootState) => ({
  maxWeek: state.weeks.maxWeek,
  minWeek: state.weeks.minWeek,
});
export const getActiveWeek = (state: RootState) => state.weeks.activeWeek;
export const getTimelineDateRange = (state: RootState): DateRange => {
  let dateRange: DateRange = {
    start: undefined,
    end: undefined,
  };
  const { weeks, timeline } = state.weeks;
  if (
    weeks &&
    timeline?.start !== undefined &&
    timeline?.end !== undefined &&
    weeks[timeline.start] !== undefined &&
    weeks[timeline.end] !== undefined
  ) {
    // Get the middle of the week as a date to avoid week being misinterpreted
    dateRange = {
      start: nextWednesday(new Date(weeks[timeline.start].startDate)),
      end: previousWednesday(new Date(weeks[timeline.end].endDate)),
    };
  }
  return dateRange;
};

export default weeksSlice.reducer;
