/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useDispatch, useSelector } from 'react-redux';
import { LEAFLET_BASEMAPS } from '../../../core/constants/Enums';
import { getBasemap, setBasemap } from '../../../core/redux/layers.slice';
import RadioButton from '../Reusable/RadioButton/RadioButton';
import './BasemapPanel.scss';

export default function BasemapPanel() {
  const selectedBasemap = useSelector(getBasemap);
  const dispatch = useDispatch();

  const handleBasemapChange = (id: LEAFLET_BASEMAPS) => {
    dispatch(setBasemap(id));
  };

  return (
    <div className="g-basemap-container" style={{ backgroundColor: 'white', height: '100%' }}>
      <RadioButton
        id={LEAFLET_BASEMAPS.Topographic}
        label="Topographic"
        name="basemaps"
        selected={selectedBasemap === LEAFLET_BASEMAPS.Topographic}
        onSelected={() => handleBasemapChange(LEAFLET_BASEMAPS.Topographic)}
      />
      <RadioButton
        id={LEAFLET_BASEMAPS.Satellite}
        label="Satellite view"
        name="basemaps"
        selected={selectedBasemap === LEAFLET_BASEMAPS.Satellite}
        onSelected={() => handleBasemapChange(LEAFLET_BASEMAPS.Satellite)}
      />
    </div>
  );
}
