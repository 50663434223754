/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import React, { ReactElement } from 'react';
import './IconButton.scss';

interface LocalProps {
  children: ReactElement;
  ariaLabel: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const IconButton = ({ children, ariaLabel, className, style, disabled, onClick }: LocalProps) => (
  <button
    aria-label={ariaLabel}
    type="button"
    className={`icon-button ${disabled ? 'disabled' : ''} ${className ? className : ''}`}
    style={style}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);
export default IconButton;
