/**
 *
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { format } from 'date-fns';
import { useState } from 'react';
import { TIMELINE_DATE } from '../../../core/constants/Enums';
import Calendar from '../Reusable/Calendar/Calendar';
import TextButton from '../Reusable/TextButton/TextButton';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import './TimelineButton.scss';

interface LocalProps {
  label: string;
  type: TIMELINE_DATE;
  date?: Date;
  className?: string;
  style?: React.CSSProperties;
  minDate?: Date;
  maxDate?: Date;
  onChange: (date: Date, type: TIMELINE_DATE) => void;
}

const TimelineButton = (props: LocalProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { label, type, date, onChange } = props;

  const handleCalendarOpen = () => {
    setOpen(true);
  };

  const handleCalendarClose = () => {
    setOpen(false);
  };

  const handleDateChange = (newDate: Date) => {
    onChange(newDate, type);
  };

  if (!date) {
    return <label className="timeline-date">{`${label} - ${format(new Date(), 'MMM dd, yyyy')}`}</label>;
  }

  return (
    <Calendar {...props} date={date} open={open} week onClose={handleCalendarClose} onChange={handleDateChange}>
      <TextButton
        leftIcon={<CalendarIcon />}
        ariaLabel={`${label} Date Calendar Button `}
        className="timeline-date"
        onClick={handleCalendarOpen}
      >{`${label} - ${format(date, 'MMM dd, yyyy')}`}</TextButton>
    </Calendar>
  );
};

export default TimelineButton;
