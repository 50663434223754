/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import './ToastMessage.scss';

interface LocalProps {
  summary?: string;
  detail?: string;
  severity?: 'success' | 'info' | 'warn' | 'error';
}

const ToastMessage = ({ summary, detail, severity = 'info' }: LocalProps) => {
  return (
    <div className={`g-Toast`}>
      <InfoIcon className={`toast-icon ${severity}`} />
      <h2 className="title">{summary}</h2>
      <p className="content">{detail}</p>
    </div>
  );
};

export default ToastMessage;
