/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import L from 'leaflet';
import { LEAFLET_BASEMAPS, LEAFLET_GROUP_LAYERS, LEAFLET_LAYERS, LEAFLET_PANES } from '../constants/Enums';

export function getLayerPane(layerId: LEAFLET_LAYERS | LEAFLET_GROUP_LAYERS | LEAFLET_BASEMAPS) {
  switch (layerId) {
    case LEAFLET_BASEMAPS.Topographic:
    case LEAFLET_BASEMAPS.Satellite:
    case LEAFLET_GROUP_LAYERS.Basemap:
      return LEAFLET_PANES.Base;
    case LEAFLET_LAYERS.Concentration:
    case LEAFLET_GROUP_LAYERS.Concentration:
      return LEAFLET_PANES.Raster;
    case LEAFLET_LAYERS.NewsPlumesRaster:
      return LEAFLET_PANES.PlumesRaster;
    case LEAFLET_LAYERS.NewsPlumesPins:
    case LEAFLET_GROUP_LAYERS.NewsPlumes:
      return LEAFLET_PANES.PlumesPins;
    default:
      return LEAFLET_PANES.Top;
  }
}

export function createPanes(mapView: L.Map) {
  Object.values(LEAFLET_PANES).forEach((id, index) => {
    const pane = mapView.createPane(id);
    pane.style.zIndex = (4 + index).toString();
  });
}

export function seeNewsPlumeOnMap(
  mapView: L.Map | undefined,
  latitude: number | undefined,
  longitude: number | undefined,
) {
  function getMapFlyToDuration(currentCenter: L.LatLng, newCenter: L.LatLng, zoom: number) {
    const distanceKm = currentCenter.distanceTo(newCenter) / 1000;
    if (distanceKm > 500) {
      if (zoom > 7) {
        return 6;
      } else {
        return 3;
      }
    } else if (distanceKm < 100) {
      return 1;
    } else {
      return 3;
    }
  }
  if (mapView !== undefined && latitude !== undefined && longitude !== undefined) {
    const currentCenter = mapView.getCenter();
    const newCenter = new L.LatLng(latitude, longitude);
    const zoom = mapView.getZoom();

    const duration = getMapFlyToDuration(currentCenter, newCenter, zoom);
    mapView.flyTo(newCenter, 13, { duration });
  }
}
