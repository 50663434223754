/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 * @format
 */

import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../core/context/app.context';
import { Locales } from '../../../core/language/Localize';
import ModalBody from '../Reusable/ModalWindow/ModalBody';
import ModalFooterButtons from '../Reusable/ModalWindow/ModalFooterButtons';
import ModalWindow from '../Reusable/ModalWindow/ModalWindow';
import TextButton from '../Reusable/TextButton/TextButton';
import TextField from '../Reusable/TextField/TextField';
import './ShareViewModal.scss';

interface LocalProps {
  visibility: boolean;
  setVisibility: (visibility: boolean) => void;
}

const ShareViewModal = ({ visibility, setVisibility }: LocalProps) => {
  const [url, setUrl] = useState<string>('');
  const { mapView } = useContext(AppContext);

  useEffect(() => {
    if (mapView) {
      const { lat, lng } = mapView.getCenter();
      const zm = mapView.getZoom();
      const currentUrl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }?lat=${lat.toFixed(2)}&lng=${lng.toFixed(2)}&zm=${zm}`;
      setUrl(currentUrl);
    }
  }, [mapView]);

  function onCancelClick() {
    setVisibility(false);
  }

  return (
    <ModalWindow title={Locales.ShareView?.title} open={visibility} size="md">
      <ModalBody>
        <div>
          <p className="share-view-title">{Locales.ShareView?.Instruction}</p>
          <TextField
            value={url}
            type="text"
            placeholder={url || 'https://spectra-basic.ghgsat.com'}
            id={''}
            onChange={setUrl}
            disabled
            size={'max'}
          />
        </div>
      </ModalBody>
      <ModalFooterButtons>
        <TextButton type="outlined" onClick={() => navigator.clipboard.writeText(url)}>
          {Locales.ShareView?.CopyLink}
        </TextButton>
        <TextButton type="filled" onClick={onCancelClick}>
          {Locales.Close}
        </TextButton>
      </ModalFooterButtons>
    </ModalWindow>
  );
};
export default ShareViewModal;
