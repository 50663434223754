/*
 *
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */
import { useSelector } from 'react-redux';
import { Locales } from '../../../core/language/Localize';
import { getLoadingMask } from '../../../core/redux/uiSettings.slice';
import './GenericLoader.scss';

const GenericLoader = () => {
  const genericLoader = useSelector(getLoadingMask);
  return (
    <div className={`generic-loader-container ${genericLoader.visible ? 'visible' : 'hidden'}`}>
      <div className="curtain" />
      <div className="generic-loader">
        {genericLoader.text ? <p>{genericLoader.text}</p> : <p>{Locales.Loading}...</p>}
      </div>
    </div>
  );
};

export default GenericLoader;
