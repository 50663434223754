/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { captureMessage, Severity } from '@sentry/react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import usePrevious from '../hooks/usePrevious';

/*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
export const trace = (tag: string) => (x: any) => {
  console.table(tag, x);
  return x;
};

// Add a leading 0
function addZeroBefore(n: number) {
  return (n < 10 ? '0' : '') + n;
}

/**
 * @description if cookie debug exist, log the info in console
 * @param log
 */
export function info(log: any) {
  const infoEnable = Cookies.get('debug');
  const date = new Date();
  const seconds = date.getSeconds();
  const minutes = date.getMinutes();
  const hour = date.getHours();
  if (infoEnable) {
    console.info(`[Info]: `, log, `🕒 ${hour}:${addZeroBefore(minutes)}:${addZeroBefore(seconds)}`);
  }
}

export function getErrorString(err: unknown) {
  return JSON.stringify(err, Object.getOwnPropertyNames(err));
}

export function logError(message: string) {
  const errorMessage = `[SPECTRA ERROR]: ${message}`;
  console.error(errorMessage);
  captureMessage(errorMessage, Severity.Error);
}

export function logGraphQLError(queryId: string, error: unknown, dataRetrieved: boolean) {
  let message = `GraphQL query for ${queryId} threw error (${getErrorString(error)})`;
  if (dataRetrieved) {
    message = message + '; Some data still successfully loaded to the card.';
  }
  console.error(message);
  captureMessage(message, Severity.Error);
}

/**
 * Debugger useEffect that will print out the dependency that caused the useEffect to re-render
 * Useful for when something is causing re-renders but it isn't clear what
 * @param effectHook the original hook function passed to the useEffect
 * @param dependencies The original list of dependencies
 * @param dependencyNames The dev friendly name to be able to easily identify what has changed
 */
export const useEffectDebugger = (
  effectHook: React.EffectCallback,
  dependencies: React.DependencyList,
  dependencyNames: string[] = [],
) => {
  const previousDeps = usePrevious(dependencies) ?? [];

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      const accumulator = accum as any;
      return {
        ...accumulator,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps as any).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  // Ignore the dependency list as this function intends to supplant the original useEffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies);
};
