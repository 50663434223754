/**
 *
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */
import L from 'leaflet';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTileManifest } from '../../../api/awsBucketApi';
import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  DEFAULT_ZOOM_LVL,
  MAX_LAT,
  MAX_LNG,
  MAX_ZOOM_LVL,
  MIN_LAT,
  MIN_LNG,
  MIN_ZOOM_LVL,
} from '../../../core/constants/Constants';
import { E_BOTTOM_PANEL } from '../../../core/constants/Enums';
import { AppContext, LAYER_ACTIONS } from '../../../core/context/app.context';
import useMainMapFunctionality from '../../../core/hooks/useMainMapFunctionality';
import { getBottomPanel } from '../../../core/redux/uiSettings.slice';
import { initWeeks } from '../../../core/redux/weeks.slice';
import { convertConcentrationManifestObjectToArray, getWeeksFromManifest } from '../../../core/utils/Manifest.utils';
import { createPanes } from '../../../core/utils/Map.utils';
import './Map.scss';

interface LocalProps {
  lat?: number;
  lng?: number;
  zm?: number;
}

export default function Map({ lat, lng, zm }: LocalProps) {
  const { setMapView, dispatchLayers } = useContext(AppContext);
  const selectedBottomPanel = useSelector(getBottomPanel);
  const [tileManifest, setTileManifest] = useState<Record<string, Record<string, string>> | undefined>();
  const mapRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  useMainMapFunctionality();

  useEffect(() => {
    const loadTiles = async (): Promise<void> => {
      const newManifest = await fetchTileManifest();
      setTileManifest(newManifest);
    };

    void loadTiles();
  }, []);

  useEffect(() => {
    if (tileManifest) {
      const tileManifestArray = convertConcentrationManifestObjectToArray(tileManifest);
      const weeks = getWeeksFromManifest(tileManifestArray);
      if (weeks.length > 0) {
        dispatch(initWeeks(weeks));
      }
    }
  }, [dispatch, tileManifest]);

  useEffect(() => {
    function initMap(): L.Map | undefined {
      const lat_view = lat && lat < MAX_LAT && lat > MIN_LAT ? lat : DEFAULT_LAT;
      const lng_view = lng && lng < MAX_LNG && lng > MIN_LNG ? lng : DEFAULT_LNG;
      const zoom = zm && zm < MAX_ZOOM_LVL && zm > MIN_ZOOM_LVL ? zm : DEFAULT_ZOOM_LVL;
      if (mapRef.current) {
        // setup map
        const newMap = L.map(mapRef.current, {
          minZoom: 3,
          maxZoom: 17,
          zoomControl: false,
          attributionControl: false,
          worldCopyJump: true,
        }).setView([lat_view, lng_view], zoom);

        L.control
          .zoom({
            position: 'bottomright',
          })
          .addTo(newMap);

        L.control
          .scale({
            position: 'bottomright',
          })
          .addTo(newMap);

        createPanes(newMap);

        return newMap;
      }
    }

    let map: L.Map | undefined = undefined;
    if (mapRef.current) {
      map = initMap();
      setMapView(map);
    }
    return () => {
      if (map) {
        map.remove();
        dispatchLayers({ type: LAYER_ACTIONS.REMOVE_ALL });
        setMapView(undefined);
      }
    };
  }, [dispatchLayers, lat, lng, setMapView, zm]);

  return (
    <div
      style={{ height: '100%', width: '100%' }}
      className={`g-map-view-map-canvas ${selectedBottomPanel === E_BOTTOM_PANEL.Timeline ? 'timeline-widgets' : ''} ${
        selectedBottomPanel === E_BOTTOM_PANEL.Legend ? 'legend-widgets' : ''
      }`}
      id="map-view"
      ref={mapRef}
    />
  );
}
