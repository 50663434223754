/**
 * /*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */
import LayerVisibilityButton from '../LayerVisibilityButton/LayerVisibilityButton';
import './LayerItem.scss';

interface LocalProps {
  layerId: string;
  showUrlLink: boolean;
  hierarchyLevel?: number;
  removable?: boolean;
}

const LayerItem = ({ layerId, showUrlLink, hierarchyLevel, removable }: LocalProps) => {
  return (
    <li className={`layer-item`}>
      <div
        className="layer-item-container"
        style={{ paddingLeft: `calc(${hierarchyLevel ?? 0 + 1} * var(--space_-1))` }}
      >
        <LayerVisibilityButton layerId={layerId} showUrlLink={showUrlLink} removable={removable} />
      </div>
    </li>
  );
};

export default LayerItem;
