/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LEAFLET_GROUP_LAYERS } from '../constants/Enums';
import { AppContext } from '../context/app.context';
import { getBasemap } from '../redux/layers.slice';
import { createBasemapLayer } from '../utils/LayerConstructor.Basemap';
import useLayer from './useLayer';

/**
 * @description This hook monitors the currently selected basemap and re-adds it if the basemap has changed
 */
function useBasemapSwitcher() {
  const selectedBasemap = useSelector(getBasemap);
  const { updateLayers } = useContext(AppContext);
  const layer = useLayer(LEAFLET_GROUP_LAYERS.Basemap);

  useEffect(() => {
    if (layer !== undefined && layer.visible) {
      if (layer.layers.length > 0) {
        layer.layers.forEach((l) => {
          layer.removeLayer(l);
        });
      }
      const basemaps = createBasemapLayer(selectedBasemap);
      layer.addManyLayers(basemaps);
    }
  }, [updateLayers, layer, selectedBasemap]);
}

export default useBasemapSwitcher;
