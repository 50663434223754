/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import React, { ReactElement } from 'react';
import './AppBarIconButton.scss';

interface LocalProps {
  id: string;
  children: ReactElement;
  ariaLabel: string;
  className?: string;
  style?: React.CSSProperties;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const AppBarIconButton = ({ id, children, ariaLabel, className, style, selected, disabled, onClick }: LocalProps) => (
  <button
    id={id}
    aria-label={ariaLabel}
    type="button"
    className={`app-bar-icon-button ${disabled ? 'disabled' : ''} ${className ? className : ''}`}
    style={style}
    onClick={onClick}
    disabled={disabled}
  >
    <div className={`icon-circle ${selected ? 'selected' : ''}`}>{children}</div>
  </button>
);
export default AppBarIconButton;
