/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { BASE_PLUMES_URL, CONCENTRATION_MANIFEST, NEWS_MANIFEST } from '../core/constants/Constants';

async function fetchFromBucket(url: string): Promise<any> {
  const response = await fetch(url, {
    headers: {},
  });
  return await response.json();
}

export async function fetchNewsManifest(): Promise<Array<Record<string, string>>> {
  return await fetchFromBucket(NEWS_MANIFEST);
}

export async function fetchTileManifest(): Promise<Record<string, Record<string, string>>> {
  return await fetchFromBucket(CONCENTRATION_MANIFEST);
}

export async function fetchPlumeRaster(url: string): Promise<ArrayBuffer | undefined> {
  const response = await fetch(BASE_PLUMES_URL + url);
  if (response.status === 200) {
    return await response.arrayBuffer();
  }
  return undefined;
}
