/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { endOfWeek, formatISO, isAfter, isBefore, parseISO, startOfWeek, sub } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import Cookies from 'js-cookie';
import { BUCKET_BASE_URL } from '../constants/Constants';
import { LEAFLET_LAYERS } from '../constants/Enums';
import { ConcentrationManifestTile, PlumeManifest, SpectraDataLayer, Week } from '../interfaces/Common.interfaces';
import { NewsArticles } from '../interfaces/User.interfaces';

const MAX_NUMBER_NEWS_ARTICLES = 50;

export function getWeeksFromManifest(manifest: Array<ConcentrationManifestTile>): Array<Week> {
  const getTimeZoneDate = (date: Date, option: 'start' | 'end', index: number) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const newDate = zonedTimeToUtc(date, timezone);
    const weekDate = option === 'start' ? startOfWeek(newDate) : endOfWeek(newDate);
    return formatISO(weekDate, { representation: 'date' });
  };
  return manifest
    .sort((a, b) => new Date(a.captured).getTime() - new Date(b.captured).getTime())
    .map(
      (tile, weekIndex): Week => ({
        ...tile,
        weekIndex,
        // week is from Sunday morning to Saturday night
        startDate: getTimeZoneDate(new Date(tile.captured), 'start', weekIndex),
        endDate: getTimeZoneDate(new Date(tile.captured), 'end', weekIndex),
        url: `${BUCKET_BASE_URL}${tile.path}{z}/{x}/{y}.png`,
      }),
    );
}

export function convertConcentrationManifestObjectToArray(
  manifest: Record<string, Record<string, string>>,
): Array<ConcentrationManifestTile> {
  const manifestObjectArray = Object.values(manifest);
  return manifestObjectArray
    .map((tile): ConcentrationManifestTile | null => {
      if (tile.id && tile.base_path && tile.captured_on) {
        return {
          id: tile.id,
          path: tile.base_path,
          captured: formatISO(utcToZonedTime(parseISO(tile.captured_on), 'UTC'), { representation: 'date' }),
        };
      } else {
        return null;
      }
    })
    .filter((tile): tile is ConcentrationManifestTile => !!tile);
}

export function convertNewsManifestToArray(manifest: Array<Record<string, string>>): Array<NewsArticles> {
  const ninetyDaysInPast = sub(new Date(), { days: 90 });
  return manifest
    .map((newsArticle): NewsArticles | null => {
      if (newsArticle.id && newsArticle.publicationDate && newsArticle.title && newsArticle.emissionDate) {
        const { id, publicationDate, title, emissionDate } = newsArticle;
        const article: NewsArticles = {
          ...newsArticle,
          id,
          publicationDate,
          title,
          emissionDate,
        };
        return article;
      }
      return null;
    })
    .filter((news): news is NewsArticles => !!news)
    .filter((news) => isAfter(new Date(news.publicationDate), ninetyDaysInPast))
    .filter((news) => isBefore(new Date(news.publicationDate), new Date()))
    .sort((a, b) => new Date(b.emissionDate).valueOf() - new Date(a.emissionDate).valueOf())
    .sort((a, b) => new Date(b.publicationDate).valueOf() - new Date(a.publicationDate).valueOf())
    .splice(0, MAX_NUMBER_NEWS_ARTICLES);
}

export function convertPlumeManifestObjectToArray(
  manifest: Record<string, Record<string, string>>,
): Array<PlumeManifest> {
  const manifestIds = Object.keys(manifest);
  return manifestIds
    .map((plumeId): PlumeManifest | null => {
      const { aws_path } = manifest[plumeId];
      if (plumeId && aws_path) {
        return {
          id: plumeId,
          url: BUCKET_BASE_URL + aws_path,
        };
      } else {
        return null;
      }
    })
    .filter((plume): plume is PlumeManifest => !!plume);
}

export function convertManifestObjectToArray(
  manifest: Record<string, Record<string, string>>,
): Array<ConcentrationManifestTile> {
  const manifestObjectArray = Object.values(manifest);
  return manifestObjectArray
    .map((tile): ConcentrationManifestTile | null => {
      if (tile.id && tile.base_path && tile.captured_on) {
        return {
          id: tile.id,
          path: tile.base_path,
          captured: formatISO(utcToZonedTime(parseISO(tile.captured_on), 'UTC'), { representation: 'date' }),
        };
      } else {
        return null;
      }
    })
    .filter((tile): tile is ConcentrationManifestTile => !!tile);
}

export function getConcentrationLayerId(weekIndex: number) {
  return `${LEAFLET_LAYERS.Concentration}-${weekIndex}`;
}

export function getNewsPlumeRasterId(plumeId: string) {
  return `${LEAFLET_LAYERS.NewsPlumesRaster}-${plumeId}`;
}

export function getArticleIdFromLayer(layer: SpectraDataLayer) {
  return layer.id.replace(`${layer.layerId}-`, '');
}

export function getNewsPlumePinId(plumeId: string) {
  return `${LEAFLET_LAYERS.NewsPlumesPins}-${plumeId}`;
}

export function markNewsArticleAsReadInCookie(id: string) {
  let currCookies = Cookies.get('news');
  if (currCookies === undefined) {
    Cookies.set('news', id, { expires: 365 });
  } else if (!currCookies.includes(id)) {
    currCookies = currCookies.concat(',' + id);
    Cookies.set('news', currCookies, { expires: 365 });
  }
}
