/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { User } from '../../../../core/interfaces/User.interfaces';
import { Locales } from '../../../../core/language/Localize';
import { addToast } from '../../../../core/redux/toast.slice';
import ModalFooterButtons from '../../Reusable/ModalWindow/ModalFooterButtons';
import TextButton from '../../Reusable/TextButton/TextButton';
import TextField, { AUTOCOMPLETE } from '../../Reusable/TextField/TextField';
import './MyProfileEdit.scss';

interface LocalProps {
  user: User;
  onCancelClick: () => void;
  onChangePasswordClick: () => void;
}

const MyProfileEdit = ({ user, onCancelClick, onChangePasswordClick }: LocalProps) => {
  const [firstName, setFirstName] = useState<string>(user.firstName);
  const [lastName, setLastName] = useState<string>(user.lastName);
  const [organization, setOrganization] = useState<string>(user.orgBasic ?? '');
  const [email, setEmail] = useState<string>(user.email);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const dispatch = useDispatch();

  function onFailedSave() {
    setErrorMessage(Locales.MyProfileActions.MyProfileSaveUnsuccessful);
    dispatch(
      addToast({
        severity: 'error',
        summary: Locales.MyProfileActions.MyProfileSaveUnsuccessful,
      }),
    );
  }

  const onSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    // try {
    //   const orgUpdate = user.organization ? null : organization;
    //   const response = await UserService.updateMyProfile(user.id, firstName, lastName, orgUpdate);
    //   if (response.errors) {
    //     onFailedSave();
    //   } else {
    //     analyticsClient.pushEvent({
    //       category: EventCategory.MyProfile,
    //       action: EventActions.MyProfile.Update,
    //     });
    //     dispatch(
    //       addToast({
    //         severity: 'success',
    //         summary: Locales.MyProfileActions.MyProfileSaved,
    //       }),
    //     );
    //     dispatch(
    //       updateMyProfile({
    //         firstName,
    //         lastName,
    //         orgBasic: organization,
    //       }),
    //     );
    //   }
    // } catch (errResponse: any) {
    //   if (errResponse.errors[0]) {
    //     const newErrorMessage = errResponse.errors[0].message ?? '';
    //     logError(newErrorMessage);
    //   } else {
    //     const errorMessage = getErrorString(errResponse);
    //     logError(errorMessage);
    //   }
    //   onFailedSave();
    // } finally {
    //   setLoading(false);
    // }
    setLoading(false);
  };

  const isValid = firstName !== '' && lastName !== '' && email !== '';
  const organizationIsAltered = organization !== user.orgBasic;
  const isAltered = firstName !== user.firstName || lastName !== user.lastName || organizationIsAltered;

  return (
    <form onSubmit={onSave}>
      <div className="my-profile-section">
        <TextField
          id="first-name"
          autocomplete={AUTOCOMPLETE.FIRST_NAME}
          label={Locales.FirstName}
          value={firstName}
          onChange={setFirstName}
          altered={user.firstName !== firstName}
        />
        <TextField
          id="last-name"
          autocomplete={AUTOCOMPLETE.LAST_NAME}
          label={Locales.LastName}
          value={lastName}
          onChange={setLastName}
          altered={user.lastName !== lastName}
        />
        <TextField
          id="organization"
          autocomplete={AUTOCOMPLETE.ORGANIZATION}
          label={Locales.Organization}
          value={organization}
          onChange={setOrganization}
          altered={user.orgBasic !== organization}
        />
        <TextField
          id="email"
          autocomplete={AUTOCOMPLETE.EMAIL}
          label={Locales.EmailAddressCap}
          value={email}
          onChange={setEmail}
          disabled
        />
        <div className="change-password-section">
          <p className="header">{Locales.Password}</p>
          <TextButton type="link-styled" onClick={onChangePasswordClick}>
            {Locales.ChangeMyPassword}
          </TextButton>
        </div>
        <p className="error-message">{errorMessage}</p>
      </div>
      <ModalFooterButtons>
        <TextButton type="outlined" onClick={onCancelClick}>
          {Locales.Cancel}
        </TextButton>
        <TextButton type="filled" loading={loading} disabled={!isValid || loading || !isAltered} submit>
          {Locales.SaveChanges}
        </TextButton>
      </ModalFooterButtons>
    </form>
  );
};

export default MyProfileEdit;
