/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useContext, useMemo } from 'react';
import { GroupLayer } from '../classes/GroupLayer';
import { AppContext } from '../context/app.context';

/**
 * @description This hook listens for changes to a given layer. It will then
 * create a near copy of the original layer as the original layer is a mutable object
 * and can mutate outside of the react lifecycle. However, it won't create a complete
 * copy so as to avoid mixing up leaflet layers by creating duplicate group layers.
 */
function useLayer(layerId: string): GroupLayer | undefined {
  const { layers } = useContext(AppContext);

  const layer = layers.find((l) => l.layerId === layerId);

  return useMemo(() => layer, [layer]);
}

export default useLayer;
