/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { GROUP_LAYERS, SENSOR_VALUES } from '../../../core/constants/Enums';
import useLayerVisibility from '../../../core/hooks/useLayerVisibility';
import { isLayerAvailable } from '../../../core/utils/Layers.utils';
import './ThirdPartySatellitesFilter.scss';
import ThirdPartySatellitesFilterButton from './ThirdPartySatellitesFilterButton';

interface LocalProps {
  layer: GROUP_LAYERS.ThirdPartyPlumes | GROUP_LAYERS.ThirdPartyPlumesZone;
}

const sensors = [
  { sensor: SENSOR_VALUES.S2, sensorName: 'Sentinel-2', label: 'S2' },
  { sensor: SENSOR_VALUES.S3, sensorName: 'Sentinel-3', label: 'S3' },
  { sensor: SENSOR_VALUES.S5, sensorName: 'Sentinel-5', label: 'S5' },
];

const ThirdPartySatellitesFilter = ({ layer }: LocalProps) => {
  const sensorList = [SENSOR_VALUES.S2, SENSOR_VALUES.S3, SENSOR_VALUES.S5];

  const disabled = !isLayerAvailable(layer);
  const { visible, isOutOfScale } = useLayerVisibility(layer);
  const isVisible = !!visible && !isOutOfScale && !disabled;

  return (
    <li className="g-third-party-satellites-filter-div">
      <div className="g-third-party-satellites-filter-container grid-container">
        {sensors.map(({ sensor, sensorName, label }) => (
          <ThirdPartySatellitesFilterButton
            key={sensorName}
            sensor={sensor}
            sensorName={sensorName}
            label={label}
            sensorList={sensorList}
            isDisabled={!isVisible}
          />
        ))}
      </div>
    </li>
  );
};
export default ThirdPartySatellitesFilter;
