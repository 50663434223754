/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { ProgressSpinner } from 'primereact/progressspinner';
import React, { ReactNode } from 'react';
import { ANIMATION_DURATION } from '../../../../core/constants/Constants';
import './TextButton.scss';

interface LocalProps {
  type?: 'filled' | 'outlined' | 'link-styled' | 'no-outline';
  children: string;
  ariaLabel?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  submit?: boolean;
  leftIcon?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const TextButton = ({
  type,
  children,
  ariaLabel,
  className,
  style,
  disabled,
  loading,
  submit,
  leftIcon,
  onClick,
}: LocalProps) => {
  let classType = '';

  if (type === 'link-styled') {
    classType = 'g-link-button';
  } else if (type === 'filled') {
    classType = 'p-button primary';
  } else if (type === 'outlined') {
    classType = 'p-button p-button-outlined';
  } else if (type === 'no-outline') {
    classType = 'p-button p-button-no-outline';
  }

  return (
    <button
      aria-label={ariaLabel ? ariaLabel : children}
      type={submit ? 'submit' : 'button'}
      className={`basic-button ${classType} ${disabled || loading ? 'disabled' : ''} ${className ? className : ''}`}
      style={style}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading && (
        <ProgressSpinner strokeWidth="2" fill="transparent" animationDuration={ANIMATION_DURATION} className="white" />
      )}
      {leftIcon && <span className="g-field-start-icon">{leftIcon}</span>}
      {children}
    </button>
  );
};

export default TextButton;
