/**
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { useDispatch, useSelector } from 'react-redux';
import { E_RIGHT_PANEL } from '../../../core/constants/Enums';
import { getRightSidePanel, setRightSidePanel } from '../../../core/redux/uiSettings.slice';
import { ReactComponent as ArrowSvg } from '../../assets/icons/arrow.svg';
import SidePanel from '../SidePanel/SidePanel';
import './SideBar.scss';

const SideBar = () => {
  const selectedRightPanel = useSelector(getRightSidePanel);
  const dispatch = useDispatch();

  const handleArrowButtonClick = () => {
    if (sidebarAvailable) {
      dispatch(setRightSidePanel(selectedRightPanel ? undefined : E_RIGHT_PANEL.ViewHistory));
    }
  };

  const sidebarAvailable = false;

  const sidebarOpen = selectedRightPanel !== undefined;

  return (
    <SidePanel
      closedWidth={8}
      className={`g-map-view-sidebar-right ${!sidebarAvailable ? 'disabled' : ''}`}
      open={sidebarOpen}
      style={{ height: '100%' }}
    >
      <div className="sidebar-right--border">
        <div className="sidebar-right-arrow-wrapper" onClick={handleArrowButtonClick}>
          <span className={`arrow ${sidebarOpen ? 'closed' : ''}`}>
            <ArrowSvg />
          </span>
        </div>
      </div>
    </SidePanel>
  );
};

export default SideBar;
