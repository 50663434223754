/*
 *
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { useSelector } from 'react-redux';
import { E_BOTTOM_PANEL } from '../../../core/constants/Enums';
import { getBottomPanel } from '../../../core/redux/uiSettings.slice';
import { Legend } from '../Legend/Legend';
import TimelinePanel from '../TimelinePanel/TimelinePanel';
import './BottomPanel.scss';

interface LocalProps {
  className?: string;
  closedHeight?: number;
  openHeight?: number;
}

const BottomPanel = ({ className, closedHeight, openHeight }: LocalProps) => {
  const selectedBottomPanel = useSelector(getBottomPanel);
  const DEFAULT_HEIGHT = 300;
  let height = !!selectedBottomPanel ? (openHeight ? openHeight : DEFAULT_HEIGHT) : closedHeight ? closedHeight : 0;
  if (selectedBottomPanel === E_BOTTOM_PANEL.Timeline) {
    height = 120;
  }

  return (
    <div className={`g-bottom-panel ${className ? className : ''}`} style={{ height: height }}>
      {selectedBottomPanel === E_BOTTOM_PANEL.Legend && <Legend />}
      {selectedBottomPanel === E_BOTTOM_PANEL.Timeline && <TimelinePanel />}
    </div>
  );
};

export default BottomPanel;
