/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import Cookies from 'js-cookie';
import getEnv from '../utils/environment';

export const deleteAllCookies = (prefix: string | undefined = undefined) => {
  const domain = getEnv() === 'local' ? '127.0.0.1' : '.ghgsat.com';
  let cookies = document.cookie.split('; ');
  cookies = cookies.map((c) => c.split('=')[0]);
  if (prefix) {
    cookies = cookies.filter((c) => c.indexOf(prefix) > -1);
  }
  cookies.map((c) => Cookies.remove(c, { domain: domain }));
};

export default deleteAllCookies;
