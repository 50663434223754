/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import L from 'leaflet';
import { LEAFLET_GROUP_LAYERS } from '../constants/Enums';
import { SpectraDataLayer, SpectraGroupLayer } from '../interfaces/Common.interfaces';
import { getLayerPane } from '../utils/Map.utils';

interface GroupLayerInput {
  id: LEAFLET_GROUP_LAYERS;
  layers?: SpectraDataLayer[];
  visible?: boolean;
  layer?: L.LayerGroup;
}

export class GroupLayer implements SpectraGroupLayer {
  id: string;
  leafletId?: number;
  pane: string;
  layerId: LEAFLET_GROUP_LAYERS;
  layers: SpectraDataLayer[];
  visible: boolean;
  layer: L.LayerGroup | undefined;

  constructor(group: GroupLayerInput) {
    this.id = group.id;
    this.pane = getLayerPane(group.id);
    this.layerId = group.id;
    this.layers = group.layers ?? [];
    this.visible = group.visible ?? true;
    this.layer = group.layer;
  }

  addLayer = (newLayers: SpectraDataLayer) => {
    if (this.layer) {
      this.layer.addLayer(newLayers.layer);
      // _leaflet_id is considered a private property but is useful for differentiating duplicate ids
      //@ts-ignore
      newLayers.leafletId = newLayers.layer._leaflet_id;
      this.layers.push(newLayers);
    }
  };

  addManyLayers = (newLayers: SpectraDataLayer[]) => {
    if (this.layer) {
      newLayers.forEach(this.addLayer);
    }
  };

  removeLayer = (layer: SpectraDataLayer) => {
    if (this.layer) {
      this.layer.removeLayer(layer.layer);
    }
    this.layers = this.layers.filter((l) => l.id !== layer.id);
  };

  createLayer = () => {
    const newGroupLayer = L.layerGroup([], {
      // id is not normally allowed on a LayerGroup but is useful for differentiating layers
      //@ts-ignore
      id: this.id,
      pane: this.pane,
    });
    this.layers = this.layers.map((l) => {
      newGroupLayer.addLayer(l.layer);
      return {
        ...l,
        // _leaflet_id is considered a private property but is useful for differentiating duplicate ids
        //@ts-ignore
        leafletId: l.layer._leaflet_id,
      };
    });
    this.layer = newGroupLayer;
    return this.layer;
  };
}
