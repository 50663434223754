/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useContext } from 'react';
import { LEAFLET_LAYER_NAMES } from '../constants/Constants';
import { AppContext, LAYER_ACTIONS } from '../context/app.context';
import { Locales } from '../language/Localize';
import useLayer from './useLayer';

/**
 * @description This hook listens to the visibility state of a given layer.
 * It will also provide other valuable information like if it is out of scale or the
 * list of urls inside of the layer / group layer. It will also provide a function
 * to toggle the visibility of the layer. If the layer cannot be found, this hook
 * will return all properties as undefined with available being false.
 */
function useLayerVisibility(layerId: string) {
  const { updateLayers } = useContext(AppContext);
  const layer = useLayer(layerId);

  if (layer) {
    let title = LEAFLET_LAYER_NAMES[layer.layerId];
    if (!title) {
      title = Locales.Undefined;
    }
    return {
      id: layer.id,
      visible: layer.visible,
      isOutOfScale: false,
      title: title,
      allUrls: undefined,
      toggleVisibility: () => {
        const action = layer.visible ? LAYER_ACTIONS.HIDE : LAYER_ACTIONS.SHOW;
        updateLayers(action, layer.id);
      },
    };
  }

  return {
    visible: undefined,
    isOutOfScale: undefined,
    title: undefined,
    allUrls: undefined,
    toggleVisibility: undefined,
  };
}

export default useLayerVisibility;
