/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Locales } from '../../../../core/language/Localize';
import ModalFooterButtons from '../../Reusable/ModalWindow/ModalFooterButtons';
import TextButton from '../../Reusable/TextButton/TextButton';
import './MyProfilePassword.scss';
import './MyProfilePasswordSuccess.scss';

interface LocalProps {
  onReturnClick: () => void;
}

const MyProfilePasswordSuccess = ({ onReturnClick }: LocalProps) => {
  return (
    <>
      <div className="password-section password-success">
        <div>
          <p className="header">{Locales.NewPasswordSaved}</p>
          <p className="message">{Locales.NewPasswordSavedMessage}</p>
        </div>
      </div>
      <ModalFooterButtons>
        <TextButton type="filled" onClick={onReturnClick}>
          {Locales.GoToMyProfile}
        </TextButton>
      </ModalFooterButtons>
    </>
  );
};

export default MyProfilePasswordSuccess;
