/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import Cookies from 'js-cookie';
import 'leaflet/dist/leaflet.css';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getUserAttributes, transferSubDomainCookiesToCurrentDomain } from './api/awsAuthApi';
import { fetchNewsManifest } from './api/awsBucketApi';
import { ReactComponent as ShareIcon } from './app/assets/icons/share.svg';
import './app/assets/styles/LeafletOverrides.scss';
import './app/assets/styles/Spectra.scss';
import AppBar from './app/components/AppBar/AppBar';
import BottomPanel from './app/components/BottomPanel/BottomPanel';
import Footer from './app/components/Footer/Footer';
import GlobalToast from './app/components/GlobalToast/GlobalToast';
import Map from './app/components/Map/Map';
import MapWidgetContainer, { Widget } from './app/components/MapWidgetContainer/MapWidgetContainer';
import QuickTimeline from './app/components/QuickTimeline/QuickTimeline';
import IconButton from './app/components/Reusable/IconButton/IconButton';
import ShareViewModal from './app/components/ShareView/ShareViewModal';
import SideBar from './app/components/SideBar/SideBar';
import TopBar from './app/components/TopBar/TopBar';
import { E_UI_SETTINGS } from './core/constants/Enums';
import TOSNotAcceptedError from './core/Errors/TOSNotAcceptedError';
import { Locales } from './core/language/Localize';
import { setNews, setReadArticleIds } from './core/redux/news.slice';
import { hideLoadingMask } from './core/redux/uiSettings.slice';
import { getIsAuthenticated, setIsAuthenticated, setUserAttributes } from './core/redux/user.slice';
import getEnv from './core/utils/environment';
import externalRedirect from './core/utils/ExternalRedirect';
import { convertNewsManifestToArray } from './core/utils/Manifest.utils';
import getUrl from './core/utils/urls';

function App() {
  const [newsManifest, setNewsManifest] = useState<Array<Record<string, string>>>([]);
  const [showShareViewDialog, setShowShareViewDialog] = useState<boolean>(false);
  const tabMenuItems: Array<MenuItem> = [
    { label: Locales.MapView, data: E_UI_SETTINGS.Map },
    {
      label: Locales.ListView,
      data: E_UI_SETTINGS.Table,
      disabled: true,
    },
  ];
  const dispatch = useDispatch();
  const authUrl = getUrl(getEnv());
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [queryParams] = useSearchParams();
  const lat = Number(queryParams.get('lat'));
  const lng = Number(queryParams.get('lng'));
  const zm = Number(queryParams.get('zm'));

  useEffect(() => {
    const checkIfAuthenticated = async () => {
      try {
        transferSubDomainCookiesToCurrentDomain();
        const user = await getUserAttributes();
        dispatch(setUserAttributes(user));
        if (!user?.attributes?.['custom:tos_accepted']) {
          throw TOSNotAcceptedError;
        }
        dispatch(setIsAuthenticated(true));
        dispatch(hideLoadingMask());
      } catch (e) {
        externalRedirect(authUrl, queryParams);
      }
    };
    void checkIfAuthenticated();
  }, [authUrl, queryParams, dispatch, isAuthenticated]);

  useEffect(() => {
    const loadNews = async (): Promise<void> => {
      const newNewsManifest = await fetchNewsManifest();
      setNewsManifest(newNewsManifest);
    };
    if (isAuthenticated) {
      void loadNews();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (newsManifest.length > 0) {
      const convertedNews = convertNewsManifestToArray(newsManifest);
      dispatch(setNews(convertedNews));
      const cookies = Cookies.get('news')?.split(',');
      if (cookies) {
        dispatch(setReadArticleIds(cookies));
      }
    }
  }, [dispatch, newsManifest]);

  return (
    <div className={`g-Layout-AppWindow private main-content-container`}>
      <TopBar />
      <div className="main-tab-menu-wrapper">
        <TabMenu className="main-tab-menu" model={tabMenuItems} activeIndex={0} />
        <GlobalToast />
      </div>
      <div>
        <div className={`g-map-layout`}>
          <div className={`g-map-view-layout`}>
            <AppBar />
            <div className="g-map-view-map-container">
              <div className="g-map-view-map">
                <Map lat={lat} lng={lng} zm={zm} />
                <MapWidgetContainer>
                  <Widget position="top-right" className="g-quicktimeline-widget">
                    <QuickTimeline />
                  </Widget>
                </MapWidgetContainer>
                <div className={`g-map-widgets-bottom-right`}>
                  <IconButton
                    ariaLabel={Locales.ShareView.title}
                    className="button-share-map button-g-esri-widget"
                    onClick={() => setShowShareViewDialog(true)}
                  >
                    <ShareIcon />
                  </IconButton>
                  {showShareViewDialog && (
                    <ShareViewModal visibility={showShareViewDialog} setVisibility={setShowShareViewDialog} />
                  )}
                </div>
              </div>
            </div>
            <SideBar />
            <BottomPanel />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
