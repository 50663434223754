/*
 *
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveWeek, getWeekExtent, getWeeks, next, previous } from '../../../core/redux/weeks.slice';
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron.svg';
import IconButton from '../Reusable/IconButton/IconButton';
import './QuickTimeline.scss';

enum DIRECTION {
  PREV,
  NEXT,
}

export default function QuickTimeline() {
  const weeks = useSelector(getWeeks);
  const activeWeek = useSelector(getActiveWeek);
  const { minWeek, maxWeek } = useSelector(getWeekExtent);
  const dispatch = useDispatch();

  const handleChangeWeek = (direction: DIRECTION) => {
    if (direction === DIRECTION.PREV) {
      dispatch(previous());
    }
    if (direction === DIRECTION.NEXT) {
      dispatch(next());
    }
  };

  let week = 'Undefined';
  if (weeks && activeWeek !== undefined) {
    const { startDate, endDate } = weeks[activeWeek];
    week = `${format(new Date(startDate), 'MMM dd')} to ${format(new Date(endDate), 'MMM dd, yyyy')}`;
  }

  return (
    <div className="g-quicktimeline-container">
      <div className="quicktimeline-button">
        <IconButton
          disabled={activeWeek === minWeek}
          ariaLabel="previous"
          onClick={() => handleChangeWeek(DIRECTION.PREV)}
        >
          <ChevronIcon />
        </IconButton>
      </div>
      <div className="quicktimeline-info">
        <p className="quicktimeline-header">Analytics From</p>
        <p className="quicktimeline-date">{week}</p>
      </div>
      <div className="quicktimeline-button">
        <IconButton disabled={activeWeek === maxWeek} ariaLabel="next" onClick={() => handleChangeWeek(DIRECTION.NEXT)}>
          <ChevronIcon style={{ transform: 'rotate(180deg)' }} />
        </IconButton>
      </div>
    </div>
  );
}
