/**
 * /*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

/** @format */
import React from 'react';
import { GROUP_LAYERS } from '../../../core/constants/Enums';
import { LayerId } from '../../../core/interfaces/Common.interfaces';
import ThirdPartySatellitesFilter from '../ThirdPartySatellitesFilter/ThirdPartySatellitesFilter';
import LayerItem from './LayerItem';

interface LocalProps {
  layerIds: (LayerId | string)[];
  type?: 'ghgsat' | 'users';
  gap?: boolean;
  removable?: boolean;
  subHeader?: string;
}

const LayerListGroup = ({ layerIds, removable, type, gap, subHeader }: LocalProps) => {
  return (
    <div className="g-WidgetPanel">
      <section className="g-WidgetPanel-Section">
        <div className="tool-panel-content body">
          <ul className={`Grid-Layout--Baseline-row layers-list ${gap ? ' _grid-row-gap-10' : ''}`}>
            {subHeader && (
              <div className="g-WidgetPanel-SubHeader">
                <h4 className="heading _h4">{subHeader}</h4>
              </div>
            )}
            {layerIds.map((layer) => {
              const showUrlLink = type === 'ghgsat' && layer !== GROUP_LAYERS.Landmarks;
              if (typeof layer !== 'string') {
                const thirdPartyPlumes = layer.children.find((thirdPartyPlumeType) => {
                  return (
                    thirdPartyPlumeType === GROUP_LAYERS.ThirdPartyPlumes ||
                    thirdPartyPlumeType === GROUP_LAYERS.ThirdPartyPlumesZone
                  );
                }) as GROUP_LAYERS.ThirdPartyPlumes | GROUP_LAYERS.ThirdPartyPlumesZone | undefined;
                return (
                  <React.Fragment key={layer.id}>
                    <LayerItem layerId={layer.id} showUrlLink={showUrlLink} />
                    {layer.children.map((childLayerId) => (
                      <LayerItem
                        key={childLayerId}
                        layerId={childLayerId}
                        showUrlLink={showUrlLink}
                        hierarchyLevel={2}
                      />
                    ))}
                    {thirdPartyPlumes !== undefined && <ThirdPartySatellitesFilter layer={thirdPartyPlumes} />}
                  </React.Fragment>
                );
              } else {
                return <LayerItem key={layer} layerId={layer} showUrlLink={showUrlLink} removable={removable} />;
              }
            })}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default LayerListGroup;
