/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { tiledMapLayer } from 'esri-leaflet';
import { LEAFLET_BASEMAPS } from '../constants/Enums';
import { SpectraDataLayer } from '../interfaces/Common.interfaces';
import { getLayerPane } from './Map.utils';

export function createBasemapLayer(id: LEAFLET_BASEMAPS): SpectraDataLayer[] {
  if (id === LEAFLET_BASEMAPS.Topographic) {
    return getTopographicBasemapLayer();
  } else if (id === LEAFLET_BASEMAPS.Satellite) {
    return getSatelliteBasemapLayer();
  } else {
    throw new TypeError(`Not a valid Group Layer type (${id})`);
  }
}

function getTopographicBasemapLayer() {
  const pane = getLayerPane(LEAFLET_BASEMAPS.Topographic);
  return [
    {
      id: LEAFLET_BASEMAPS.Topographic,
      layerId: LEAFLET_BASEMAPS.Topographic,
      pane: pane,
      layer: tiledMapLayer({
        url: 'https://server.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer',
        pane: pane,
      }),
    },
  ];
}

function getSatelliteBasemapLayer() {
  const pane = getLayerPane(LEAFLET_BASEMAPS.Satellite);
  const imagery = {
    id: LEAFLET_BASEMAPS.Satellite + '-imagery',
    layerId: LEAFLET_BASEMAPS.Satellite,
    pane: pane,
    layer: tiledMapLayer({
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
      pane: pane,
    }),
  };

  const mapReference = {
    id: LEAFLET_BASEMAPS.Satellite + '-reference',
    layerId: LEAFLET_BASEMAPS.Satellite,
    pane: pane,
    layer: tiledMapLayer({
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer',
      pane: pane,
    }),
  };
  return [imagery, mapReference];
}
