/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hotjar } from 'react-hotjar';
import { Integrations } from '@sentry/tracing';
import { init } from '@sentry/react';
import { Provider } from 'react-redux';
import { store } from './core/redux/store';
import { AppContextProvider } from './core/context/app.context';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GenericLoader from './app/components/GenericLoader/GenericLoader';
import AwsCookieStorage from './api/AwsCookieStorage';
import getEnv from './core/utils/environment';

Auth.configure({
  ...awsconfig,
  storage: new AwsCookieStorage(false),
});
const environment = getEnv();
const apiUrl = environment === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

init({
  dsn: 'https://b5738cd6558949e49666c40e313093c7@1511-sentry.ghgsat.com/7',
  integrations: [new Integrations.BrowserTracing()],
  environment,
  maxValueLength: 5000,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: environment !== 'development' && environment !== 'local',
});

const isHotjarEnabled = Boolean(process.env.REACT_APP_HOTJAR_ENABLED);
const hotjarId = Number(process.env.REACT_APP_HOTJAR_ID);
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION) || 6;

if (isHotjarEnabled && hotjarId) {
  hotjar.initialize(hotjarId, hotjarVersion);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppContextProvider>
        <GenericLoader />
        <Router>
          <Routes>
            <Route path={'/'} element={<App />} />
          </Routes>
        </Router>
      </AppContextProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
