/*
 *
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { ReactElement } from 'react';
import './SidePanel.scss';

interface LocalProps {
  open: boolean;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  closedWidth?: number;
  openWidth?: number;
  children?: ReactElement;
}

const SidePanel = ({ open, title, className, style, closedWidth, openWidth, children }: LocalProps) => {
  const DEFAULT_WIDTH = 300;
  const width = open ? (openWidth ? openWidth : DEFAULT_WIDTH) : closedWidth ? closedWidth : 0;
  return (
    <div className={`g-side-panel ${className ? className : ''}`} style={{ width: width, ...style }}>
      {title && (
        <header className="g-side-panel-header">
          <h4 className="heading">{title}</h4>
        </header>
      )}
      <div className={`g-side-panel-body ${title ? 'pad-header' : ''}`}>{children}</div>
    </div>
  );
};

export default SidePanel;
