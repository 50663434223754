/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

// No exported types for georaster
//@ts-ignore
import parseGeoraster from 'georaster';
import GeoRasterLayer from 'georaster-layer-for-leaflet';
import L from 'leaflet';
import { fetchPlumeRaster } from '../../api/awsBucketApi';
import { isInEnum, LEAFLET_GROUP_LAYERS, LEAFLET_LAYERS } from '../constants/Enums';
import { GeoTiff, GroupLayerType, SpectraDataLayer } from '../interfaces/Common.interfaces';
import { NewsArticles } from '../interfaces/User.interfaces';
import { getNewsPlumePinId, getNewsPlumeRasterId } from './Manifest.utils';
import { getLayerPane } from './Map.utils';

/**
 * @description takes in a layer id and checks the corresponding feature availability for whether
 * it should be available based on the product order
 */
export function isLayerAvailable(layerId: string): boolean {
  switch (layerId) {
    case LEAFLET_GROUP_LAYERS.Concentration:
      return true;
    default:
      return false;
  }
}

export function stripBundleLayerId(layerId: string): LEAFLET_LAYERS {
  function checkIfInLeafletLayersEnum(id: string): LEAFLET_LAYERS {
    if (isInEnum(id, LEAFLET_LAYERS)) {
      return id as LEAFLET_LAYERS;
    } else {
      throw Error(`${layerId} is not included in LEAFLET_LAYERS`);
    }
  }
  const layerArray = layerId.split('-');
  if (layerArray.length > 0) {
    const [strippedLayerId] = layerArray;
    return checkIfInLeafletLayersEnum(strippedLayerId);
  }
  return checkIfInLeafletLayersEnum(layerId);
}

export async function loadNewsPlumes(newsArticles: Array<NewsArticles>, onPinClick: (article: NewsArticles) => void) {
  const ghgsatMarkerIcon = L.icon({
    iconUrl: require('../../app/assets/icons/pin.png'),
    iconSize: [25, 35],
    iconAnchor: [12.5, 35],
  });
  const layers = await Promise.all(
    newsArticles.map(async (article) => {
      const { id, latitude, longitude, plumeUrl } = article;
      if (latitude && longitude && plumeUrl) {
        const newPinLayerUid = getNewsPlumePinId(id);
        const pin: SpectraDataLayer = {
          id: newPinLayerUid,
          layerId: LEAFLET_LAYERS.NewsPlumesPins,
          pane: getLayerPane(LEAFLET_LAYERS.NewsPlumesPins),
          layer: new L.Marker([latitude, longitude], {
            icon: ghgsatMarkerIcon,
            pane: getLayerPane(LEAFLET_LAYERS.NewsPlumesPins),
          }).on('click', () => onPinClick(article)),
        };
        return pin;
      }
      return null;
    }),
  );
  return layers.flatMap((layers) => layers).filter((layer): layer is SpectraDataLayer => !!layer);
}

export async function getGeoTiff(
  existingGeoTiffs: GeoTiff[],
  url: string | undefined,
  addRasterPlume: (geoTiff: GeoTiff) => void,
): Promise<GeoTiff | undefined> {
  if (!url) {
    return undefined;
  }
  let geoTiff = existingGeoTiffs.find((file) => file.articleUrl === url);
  if (!geoTiff) {
    const arrayBuffer = await fetchPlumeRaster(url);
    if (arrayBuffer) {
      geoTiff = {
        articleUrl: url,
        file: await parseGeoraster(arrayBuffer),
      };
      addRasterPlume(geoTiff);
    }
  }
  return geoTiff;
}

export function loadPlumeRaster(groupLayer: GroupLayerType, geoTiff: GeoTiff | undefined, articleId: string): void {
  if (!geoTiff) {
    return undefined;
  }
  const newLayerUid = getNewsPlumeRasterId(articleId);
  const plumeRaster: SpectraDataLayer = {
    id: newLayerUid,
    layerId: LEAFLET_LAYERS.NewsPlumesRaster,
    pane: getLayerPane(LEAFLET_LAYERS.NewsPlumesRaster),
    layer: new GeoRasterLayer({
      //@ts-ignore
      id: newLayerUid,
      attribution: 'GHGSat',
      georaster: geoTiff.file,
      resolution: 128,
      pane: getLayerPane(LEAFLET_LAYERS.NewsPlumesRaster),
      minZoom: 10,
    }),
  };
  groupLayer.addLayer(plumeRaster);
}

export function removePlumeRasters(groupLayer: GroupLayerType) {
  if (groupLayer.layers.length > 0) {
    groupLayer.layers.forEach((l) => {
      if (l.layerId === LEAFLET_LAYERS.NewsPlumesRaster) {
        groupLayer.removeLayer(l);
      }
    });
  }
}
