/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

const getEnv = () => {
  const host = window.location.hostname;
  let environment = 'local';
  if (host === 'spectra-basic.ghgsat.com') {
    environment = 'production';
  } else if (host === 'spectra-basic-staging.ghgsat.com') {
    environment = 'staging';
  } else if (host === 'spectra-basic-dev.ghgsat.com') {
    environment = 'development';
  }
  return environment;
};

export default getEnv;
