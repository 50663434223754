/**
 * /*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */
import { LAYER_NAMES, LEAFLET_LAYER_NAMES } from '../../../core/constants/Constants';
import useLayerVisibility from '../../../core/hooks/useLayerVisibility';
import { Locales } from '../../../core/language/Localize';
import { logError } from '../../../core/utils/Debug.utils';
import { isLayerAvailable } from '../../../core/utils/Layers.utils';
import { ReactComponent as EyeSlashIcon } from '../../assets/icons/eye-slash.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import IconButton from '../Reusable/IconButton/IconButton';
import Tooltip from '../Reusable/Tooltip/Tooltip';
import './LayerVisibilityButton.scss';

interface LocalProps {
  layerId: string;
  label?: string;
  showUrlLink?: boolean;
  removable?: boolean;
}

const LayerVisibilityButton = ({ layerId, label, showUrlLink }: LocalProps) => {
  const { visible, isOutOfScale, title, toggleVisibility } = useLayerVisibility(layerId);

  let layerName = label;
  if (!layerName) {
    layerName = title ? title : LAYER_NAMES[layerId];
  }
  if (!layerName) {
    layerName = title ? title : LEAFLET_LAYER_NAMES[layerId];
  }
  if (!layerName) {
    const err = `No layer name has been defined for ${layerId}`;
    logError(err);
    layerName = Locales.Undefined;
  }

  const handleVisibilityToggleClick = () => {
    if (!disabled) {
      if (toggleVisibility) {
        toggleVisibility();
      } else {
        const err = `toggleVisibility called without being defined for ${layerName}`;
        logError(err);
      }
    }
  };

  const disabled = !isLayerAvailable(layerId);
  const isLinkDisabled = true;

  const isVisible = !!visible && !isOutOfScale && !disabled;
  return (
    <>
      <div
        className={`btn-layer-visibility ${isVisible ? 'visible' : ''} ${disabled ? 'disabled' : ''} ${
          isOutOfScale ? 'isOutOfScale' : ''
        }`}
      >
        <button
          aria-label={`${layerName} Visibility Button`}
          type="button"
          className={`btn-layer ${disabled ? 'disabled' : ''}`}
          onClick={handleVisibilityToggleClick}
          disabled={disabled}
        >
          <span className="g-icon-eye">{isVisible ? <EyeIcon /> : <EyeSlashIcon />}</span>
          <span>{layerName}</span>
        </button>
        {isOutOfScale && (
          <Tooltip content={Locales.InvisibleAtCurrentScale}>
            <p className="layer-info g-icon-info">
              <InfoIcon />
            </p>
          </Tooltip>
        )}
      </div>
      {showUrlLink && (
        <IconButton
          ariaLabel={Locales.CopyLink}
          className={`g-icon-link`}
          onClick={handleVisibilityToggleClick}
          disabled={isLinkDisabled}
        >
          <LinkIcon />
        </IconButton>
      )}
    </>
  );
};

export default LayerVisibilityButton;
