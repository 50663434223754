/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Locales } from '../../../core/language/Localize';
import { getUser } from '../../../core/redux/user.slice';
import ModalWindow from '../Reusable/ModalWindow/ModalWindow';
import MyProfileEdit from './myProfilePages/MyProfileEdit';
import MyProfilePasswordEdit from './myProfilePages/MyProfilePasswordEdit';
import MyProfilePasswordSuccess from './myProfilePages/MyProfilePasswordSuccess';

interface Props {
  open: boolean;
  setOpen: (visibility: boolean) => void;
}

enum MYPROFILE_PAGES {
  EDIT = 'EDIT',
  PASSWORD_EDIT = 'PASSWORD',
  PASSWORD_SUCCESS = 'PASSWORD_SUCCESS',
}

const MyProfileModal = ({ open, setOpen }: Props) => {
  const user = useSelector(getUser);
  const [page, setPage] = useState<MYPROFILE_PAGES>(MYPROFILE_PAGES.EDIT);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalWindow title={Locales.MyProfile} open={open} size="sm">
      {page === MYPROFILE_PAGES.EDIT && (
        <MyProfileEdit
          user={user}
          onCancelClick={handleClose}
          onChangePasswordClick={() => setPage(MYPROFILE_PAGES.PASSWORD_EDIT)}
        />
      )}
      {page === MYPROFILE_PAGES.PASSWORD_EDIT && (
        <MyProfilePasswordEdit
          userEmail={user.email}
          onCancelClick={() => setPage(MYPROFILE_PAGES.EDIT)}
          onPasswordSuccess={() => setPage(MYPROFILE_PAGES.PASSWORD_SUCCESS)}
        />
      )}
      {page === MYPROFILE_PAGES.PASSWORD_SUCCESS && (
        <MyProfilePasswordSuccess onReturnClick={() => setPage(MYPROFILE_PAGES.EDIT)} />
      )}
    </ModalWindow>
  );
};

export default MyProfileModal;
