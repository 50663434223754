/*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import L from 'leaflet';
import React, { createContext, useCallback, useReducer, useState } from 'react';
import { GroupLayer } from '../classes/GroupLayer';
import { GeoTiff } from '../interfaces/Common.interfaces';
import { info } from '../utils/Debug.utils';

export enum LAYER_ACTIONS {
  ADD,
  REMOVE,
  SHOW,
  HIDE,
  REMOVE_ALL,
}
const actions = ['ADD', 'REMOVE', 'SHOW', 'HIDE', 'REMOVE_ALL'];

function addLayer(mapView: L.Map, layer: GroupLayer): GroupLayer {
  if (!layer.layer) {
    layer.layer = layer.createLayer();
  }

  // Check that there is an html element before trying to add a layer as the leaflet map object
  // can get out of sync with react
  let pane = mapView.getPane(layer.pane);
  if (pane && document.getElementsByClassName(pane.className).length > 0) {
    mapView.addLayer(layer.layer);
  }

  // _leaflet_id is considered a private property but is useful for differentiating duplicate ids
  //@ts-ignore
  layer.leafletId = layer.layer._leaflet_id;
  layer.visible = true;
  return layer;
}

export default function layerReducer(
  state: GroupLayer[],
  action: { type: LAYER_ACTIONS; payload?: GroupLayer | string; mapView?: L.Map },
): GroupLayer[] {
  const { mapView } = action;

  // Clear the layers if map is not available
  if (!mapView) {
    return [];
  }

  info(`${actions[action.type]} called`);
  switch (action.type) {
    case LAYER_ACTIONS.ADD:
      if (action.payload instanceof GroupLayer) {
        const layer = addLayer(mapView, action.payload);
        info(`${layer.id} (${layer.leafletId}) added to the map`);
        return [
          ...state.filter((l) => {
            if (l.id === layer.id) {
              info(`${l.id} (${l.leafletId}) filtered due to a newer layer`);
            }
            return l.id !== layer.id;
          }),
          layer,
        ];
      }
      break;
    case LAYER_ACTIONS.REMOVE:
      if (typeof action.payload === 'string') {
        const layer = state.find((l) => l.id === action.payload);
        if (layer) {
          if (layer.layer) {
            mapView.removeLayer(layer.layer);
          }
          info(`${layer.id} (${layer.leafletId}) removed from the map`);
          return state.filter((l) => l.leafletId !== layer.leafletId);
        }
      }
      break;
    case LAYER_ACTIONS.SHOW:
      if (typeof action.payload === 'string') {
        return state.map((l) => {
          if (l.id === action.payload) {
            const newLayer = addLayer(mapView, l);
            info(`${newLayer.id} (${newLayer.leafletId}) showing on the map`);
            newLayer.visible = true;
            return newLayer;
          }
          return l;
        });
      }
      break;
    case LAYER_ACTIONS.HIDE:
      if (typeof action.payload === 'string') {
        return state.map((l) => {
          if (l.id === action.payload) {
            if (l.layer) {
              mapView.removeLayer(l.layer);
              l.layer = undefined;
            }
            info(`${l.id} (${l.leafletId}) removed from the map`);
            l.visible = false;
            return l;
          }
          return l;
        });
      }
      break;
    case LAYER_ACTIONS.REMOVE_ALL:
      return [];
    default:
      return state;
  }
  return state;
}

// mapView and layers are mutable objects and must be kept outside of the redux store
type Context = {
  mapView: L.Map | undefined;
  setMapView: (view: L.Map | undefined) => void | React.SetStateAction<L.Map | undefined>;
  newsRasterFiles: GeoTiff[];
  addNewsRasterFile: (view: GeoTiff) => void;
  layers: GroupLayer[];
  updateLayers: (type: LAYER_ACTIONS, payload: GroupLayer | string) => void;
  dispatchLayers: React.Dispatch<{
    type: LAYER_ACTIONS;
    payload?: GroupLayer | string;
    mapView?: L.Map | undefined;
  }>;
};

export const AppContext = createContext<Context>({
  mapView: undefined,
  setMapView: () => undefined,
  newsRasterFiles: [],
  addNewsRasterFile: () => undefined,
  layers: [],
  updateLayers: () => undefined,
  dispatchLayers: () => undefined,
});

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [mapView, setMapView] = useState<L.Map | undefined>();
  const [newsRasterFiles, setNewsRasterFiles] = useState<GeoTiff[]>([]);
  const [layers, dispatchLayers] = useReducer(layerReducer, []);

  const updateLayers = useCallback(
    (type: LAYER_ACTIONS, payload: GroupLayer | string) => {
      dispatchLayers({ type: type, payload: payload, mapView: mapView });
    },
    [mapView],
  );

  const addNewsRasterFile = (newFile: GeoTiff) => {
    setNewsRasterFiles((prev) => [...prev, newFile]);
  };

  return (
    <AppContext.Provider
      value={{
        mapView,
        setMapView,
        newsRasterFiles,
        addNewsRasterFile,
        layers,
        updateLayers,
        dispatchLayers,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
