/*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { E_LEFT_PANEL, E_RIGHT_PANEL, E_BOTTOM_PANEL } from '../constants/Enums';

import { RootState } from './store';

interface LoadingMask {
  visible: boolean;
  text: string | undefined;
}
interface LocalState {
  loadingMask: LoadingMask;
  genericLoading: boolean;
  leftSidePanel: E_LEFT_PANEL | undefined;
  rightSidePanel: E_RIGHT_PANEL | undefined;
  bottomPanel: E_BOTTOM_PANEL | undefined;
  alertBanner: boolean;
}

const initialState: LocalState = {
  loadingMask: {
    visible: true,
    text: undefined,
  },
  genericLoading: false,
  leftSidePanel: undefined,
  rightSidePanel: undefined,
  bottomPanel: E_BOTTOM_PANEL.Timeline,
  alertBanner: true,
};

export const uiSettingsSlice = createSlice({
  name: 'uiSettings',
  initialState,
  reducers: {
    showLoadingMask: (state, action: PayloadAction<string | undefined>) => {
      state.loadingMask.visible = true;
      state.loadingMask.text = action.payload;
    },
    hideLoadingMask: (state) => {
      state.loadingMask.visible = false;
      state.loadingMask.text = undefined;
    },
    setGenericLoading: (state, action: PayloadAction<boolean>) => {
      state.genericLoading = action.payload;
    },
    setLeftSidePanel: (state, action: PayloadAction<E_LEFT_PANEL | undefined>) => {
      state.leftSidePanel = action.payload;
      if (state.bottomPanel !== undefined) {
        state.bottomPanel = undefined;
      }
    },
    setRightSidePanel: (state, action: PayloadAction<E_RIGHT_PANEL | undefined>) => {
      state.rightSidePanel = action.payload;
      if (state.bottomPanel !== undefined) {
        state.bottomPanel = undefined;
      }
    },
    setBottomPanel: (state, action: PayloadAction<E_BOTTOM_PANEL | undefined>) => {
      state.bottomPanel = action.payload;
      if (state.leftSidePanel !== undefined) {
        state.leftSidePanel = undefined;
      }
      if (state.rightSidePanel !== undefined) {
        state.rightSidePanel = undefined;
      }
    },
    setAlertBanner: (state, action: PayloadAction<boolean>) => {
      state.alertBanner = action.payload;
    },
    clearUISettings: (state) => {
      state.genericLoading = false;
      state.leftSidePanel = undefined;
      state.rightSidePanel = E_RIGHT_PANEL.ViewHistory;
      state.bottomPanel = undefined;
      state.alertBanner = true;
    },
  },
});

export const {
  showLoadingMask,
  hideLoadingMask,
  setGenericLoading,
  setLeftSidePanel,
  setRightSidePanel,
  setBottomPanel,
  setAlertBanner,
  clearUISettings,
} = uiSettingsSlice.actions;

export const getLoadingMask = (state: RootState) => state.uiSettings.loadingMask;
export const getGenericLoading = (state: RootState) => state.uiSettings.genericLoading;
export const getLeftSidePanel = (state: RootState) => state.uiSettings.leftSidePanel;
export const getRightSidePanel = (state: RootState) => state.uiSettings.rightSidePanel;
export const getBottomPanel = (state: RootState) => state.uiSettings.bottomPanel;
export const getAlertBanner = (state: RootState) => state.uiSettings.alertBanner;

export default uiSettingsSlice.reducer;
