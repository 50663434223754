/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewsArticles } from '../interfaces/User.interfaces';
import { markNewsArticleAsReadInCookie } from '../utils/Manifest.utils';
import { RootState } from './store';

const MAX_PLUMES_TO_DISPLAY = 1;

interface LocalState {
  news: Array<NewsArticles>;
  openArticleIds: Array<string>;
  readArticleIds: Array<string>;
  latestOpenArticleId: string | undefined;
  plumeDisplayedArticleIds: Array<string>;
  latestPlumeDisplayedArticleId: string | undefined;
}

const initialState: LocalState = {
  news: [],
  openArticleIds: [],
  readArticleIds: [],
  latestOpenArticleId: undefined,
  plumeDisplayedArticleIds: [],
  latestPlumeDisplayedArticleId: undefined,
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews: (state, action: PayloadAction<Array<NewsArticles>>) => {
      if (action.payload.length > 0) {
        state.news = action.payload;
      }
    },
    setReadArticleIds: (state, action: PayloadAction<Array<string>>) => {
      state.readArticleIds = action.payload;
    },
    showArticlePlume: (state, action: PayloadAction<string>) => {
      if (!state.plumeDisplayedArticleIds.includes(action.payload)) {
        state.plumeDisplayedArticleIds = [
          action.payload,
          ...state.plumeDisplayedArticleIds.slice(0, MAX_PLUMES_TO_DISPLAY - 1),
        ];
      }
      state.latestPlumeDisplayedArticleId = action.payload;
    },
    openArticle: (state, action: PayloadAction<string>) => {
      if (!state.openArticleIds.includes(action.payload)) {
        if (!state.readArticleIds.includes(action.payload)) {
          markNewsArticleAsReadInCookie(action.payload);
          state.readArticleIds = [...state.readArticleIds, action.payload];
        }
        state.openArticleIds = [...state.openArticleIds, action.payload];
      }
      state.latestOpenArticleId = action.payload;
    },
    closeArticle: (state, action: PayloadAction<string>) => {
      state.openArticleIds = state.openArticleIds.filter((id) => id !== action.payload);
    },
    clearLatestOpenArticleId: (state) => {
      state.latestOpenArticleId = undefined;
    },
  },
});

export const { setNews, setReadArticleIds, closeArticle, showArticlePlume, openArticle, clearLatestOpenArticleId } =
  newsSlice.actions;

export const getNews = (state: RootState) => state.news.news;
export const getOpenArticles = (state: RootState) => state.news.openArticleIds;
export const getReadArticles = (state: RootState) => state.news.readArticleIds;
export const getLatestOpenArticleId = (state: RootState) => state.news.latestOpenArticleId;
export const getLatestPlumeDisplayedArticleId = (state: RootState) => state.news.latestPlumeDisplayedArticleId;
export const getPlumeDisplayedArticleIds = (state: RootState) => state.news.plumeDisplayedArticleIds;

export default newsSlice.reducer;
