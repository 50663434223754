/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { SENSOR_VALUES } from '../../../core/constants/Enums';
import Tooltip from '../Reusable/Tooltip/Tooltip';
import './ThirdPartySatellitesFilter.scss';

interface LocalProps {
  sensor: SENSOR_VALUES;
  sensorName: string;
  label: string;
  sensorList: SENSOR_VALUES[];
  isDisabled: boolean;
}

const ThirdPartySatellitesFilterButton = ({ sensor, sensorName, label, sensorList, isDisabled }: LocalProps) => {
  let isActive = sensorList.some((currSensor) => currSensor === sensor);
  if (isDisabled === true) {
    isActive = false;
  }
  return (
    <Tooltip content={sensorName} placement="bottom">
      <button className={`p-button-sm g-third-party-filter-button ${isActive ? 'isActive' : ''}`} disabled={isDisabled}>
        {label}
      </button>
    </Tooltip>
  );
};
export default ThirdPartySatellitesFilterButton;
