/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { ReactNode } from 'react';
import './ModalBody.scss';

interface LocalProps {
  className?: string;
  style?: React.CSSProperties;
  children: ReactNode;
}

const ModalBody = ({ className, style, children }: LocalProps) => {
  return (
    <div style={style} className={`modal-body ${className ? className : ''}`}>
      {children}
    </div>
  );
};

export default ModalBody;
