/*
 *
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { ReactNode } from 'react';
import './MapWidgetContainer.scss';

interface LocalProps {
  children: ReactNode;
}

export default function MapWidgetContainer({ children }: LocalProps) {
  return <div className="g-map-widget-container">{children}</div>;
}

interface WidgetProps {
  position:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'middle-left'
    | 'middle-center'
    | 'middle-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right';
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export function Widget({ position, children, className, style }: WidgetProps) {
  return (
    <div className={`g-widget-${position} ${className ? className : ''}`} style={style}>
      <div className="g-clickable-widget">{children}</div>
    </div>
  );
}
