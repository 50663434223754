/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import './RadioButton.scss';

interface LocalProps {
  id: string;
  label: string;
  selected: boolean;
  name: string;
  onSelected: () => void;
}

export default function RadioButton({ id, label, selected, onSelected }: LocalProps) {
  return (
    <div className="g-radio-button">
      <input
        className="g-radio-button-input"
        type="radio"
        id={id}
        value={id}
        checked={selected}
        onChange={onSelected}
      />
      <label className="g-radio-button-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
