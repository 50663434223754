/**
 *
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { endOfWeek, isAfter, startOfWeek } from 'date-fns';
import { Slider, SliderValueType } from 'primereact/slider';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GROUP_LAYERS, TIMELINE_DATE } from '../../../core/constants/Enums';
import { DateRange } from '../../../core/interfaces/Common.interfaces';
import { Locales } from '../../../core/language/Localize';
import {
  getActiveWeek,
  getTimelineDateRange,
  getWeekExtent,
  getWeeks,
  setActiveWeek,
  setTimeline,
} from '../../../core/redux/weeks.slice';
import { getDateWithoutTimezone } from '../../../core/utils/Date.utils';
import LayerListGroup from '../LayerList/LayerListGroup';
import TimelineButton from './TimelineButton';
import './TimelinePanel.scss';

const TODAY = new Date();

const TimelinePanel = () => {
  const weeks = useSelector(getWeeks);
  const activeWeek = useSelector(getActiveWeek);
  const { maxWeek, minWeek } = useSelector(getWeekExtent);
  const dateRange = useSelector(getTimelineDateRange);
  const [playing, setPlaying] = useState<boolean>(false);
  const dispatch = useDispatch();

  const SLIDER_ID = 'timlineSlider';
  const SLIDER_HANDLE_CLASSNAME = 'p-slider-handle';
  const STEP_DURATION = 3000;

  const handleChange = (value: SliderValueType) => {
    if (!isNaN(Number(value))) {
      setPlaying(false);
      dispatch(setActiveWeek(Number(value)));
    }
  };

  const handleCalendarChange = (newDate: Date, label: TIMELINE_DATE) => {
    if (weeks) {
      let newDateRange: DateRange = {
        start: undefined,
        end: undefined,
      };
      if (dateRange) {
        newDateRange = { ...dateRange };
      }
      if (label === TIMELINE_DATE.Start) {
        newDateRange.start = newDate;
      } else if (label === TIMELINE_DATE.End) {
        newDateRange.end = newDate;
      }
      dispatch(setTimeline(newDateRange));
    }
  };

  let minSliderIndex = 0;
  let maxSliderIndex = 0;

  if (minWeek !== undefined && maxWeek !== undefined) {
    minSliderIndex = minWeek;
    maxSliderIndex = maxWeek;
  }
  if (weeks && dateRange) {
    const { start, end } = dateRange;
    if (start) {
      const startWeek = weeks.find((week) => isAfter(new Date(week.captured), start));
      if (startWeek) {
        minSliderIndex = startWeek.weekIndex;
      }
    }
    if (end) {
      const endWeek = weeks.find((week) => isAfter(new Date(week.captured), end));
      if (endWeek) {
        maxSliderIndex = endWeek.weekIndex;
      }
    }
  }

  useEffect(() => {
    const handleElementClick = () => {
      //set slider to play from start if play is clicked at the end
      if (!playing && activeWeek === maxSliderIndex) {
        setPlaying(true);
        dispatch(setActiveWeek(minSliderIndex));
      } else {
        setPlaying((prevState) => !prevState);
        if (!playing && activeWeek !== undefined) {
          dispatch(setActiveWeek(activeWeek + 1));
        }
      }
    };
    let sliderHandleElement: Element | undefined;
    const sliderHandleElementList = document.getElementById(SLIDER_ID)?.getElementsByClassName(SLIDER_HANDLE_CLASSNAME); // required to see if the handle on the primereact slider has been clicked
    if (sliderHandleElementList !== undefined && sliderHandleElementList[0] instanceof HTMLElement) {
      // eslint-disable-next-line prefer-destructuring
      sliderHandleElement = sliderHandleElementList[0];
      sliderHandleElement.addEventListener('click', handleElementClick);
    }
    return () => {
      if (sliderHandleElement) {
        sliderHandleElement.removeEventListener('click', handleElementClick);
      }
    };
  }, [activeWeek, dispatch, maxSliderIndex, minSliderIndex, playing]);

  // play the slider on playing and pause if we reach the end of the slider
  useEffect(() => {
    let playingTimeout: NodeJS.Timeout | undefined = undefined;

    if (playing && activeWeek !== undefined) {
      playingTimeout = setTimeout(() => {
        dispatch(setActiveWeek(activeWeek + 1));
      }, STEP_DURATION);
    }
    return () => {
      if (playingTimeout) {
        clearTimeout(playingTimeout);
      }
    };
  }, [playing, activeWeek, dispatch]);

  // stop the slider once it reaches the end
  useEffect(() => {
    const playingTimeout: NodeJS.Timeout | undefined = undefined;
    if (playing && activeWeek === maxSliderIndex && maxSliderIndex !== 0) {
      setPlaying(false);
    }
    return () => {
      if (playingTimeout) {
        clearTimeout(playingTimeout);
      }
    };
  }, [playing, activeWeek, dispatch, maxSliderIndex, minSliderIndex]);

  const minCalendarDate =
    weeks && minWeek !== undefined ? getDateWithoutTimezone(new Date(weeks[minWeek].startDate)) : TODAY;
  const maxCalendarDate =
    weeks && maxWeek !== undefined ? getDateWithoutTimezone(new Date(weeks[maxWeek].endDate)) : TODAY;

  return (
    <div className={`g-basic-timeline-panel-wrapper visible`}>
      <div className="g-basic-timeline-panel">
        <div className="g-timeline-layers">
          <LayerListGroup layerIds={[GROUP_LAYERS.Concentration]} />
        </div>
        <TimelineButton
          label={Locales.Start}
          type={TIMELINE_DATE.Start}
          date={dateRange?.start}
          minDate={minCalendarDate}
          maxDate={dateRange?.end ? startOfWeek(dateRange.end, { weekStartsOn: 6 }) : undefined}
          onChange={handleCalendarChange}
        />
        <div className={`timeline-bar ${playing ? 'pause' : ''}`}>
          <Slider
            id={SLIDER_ID}
            step={1}
            min={minSliderIndex}
            max={maxSliderIndex}
            value={activeWeek}
            onChange={(e) => handleChange(e.value)}
          />
        </div>
        <TimelineButton
          label={Locales.End}
          date={dateRange?.end}
          type={TIMELINE_DATE.End}
          minDate={dateRange?.start ? getDateWithoutTimezone(endOfWeek(dateRange.start)) : undefined}
          maxDate={maxCalendarDate}
          onChange={handleCalendarChange}
        />
      </div>
    </div>
  );
};

export default TimelinePanel;
