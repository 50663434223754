/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { LANG } from '../constants/Enums';
import { Locales } from '../language/Localize';

interface LangState {
  lang: string;
}

const initialState: LangState = {
  lang: LANG.en,
};

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    changeLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
      Locales.setLanguage(action.payload);
    },
  },
});

export const { changeLang } = langSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLang = (state: RootState) => state.lang.lang;

export default langSlice.reducer;
