/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

const getUrl = (environment: string) => {
  if (environment === 'production') {
    return process.env.REACT_APP_PROD_AUTH_URL ?? '';
  }
  if (environment === 'staging') {
    return process.env.REACT_APP_STAGING_AUTH_URL ?? '';
  }
  if (environment === 'development') {
    return process.env.REACT_APP_DEV_AUTH_URL ?? '';
  }
  if (environment === 'local') {
    return process.env.REACT_APP_LOCALDEV_AUTH_URL ?? '';
  }
  return '';
};

export default getUrl;
