/*
 *
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */
import Tippy from '@tippyjs/react/headless';
import { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import './Tooltip.scss';

interface LocalProps {
  content?: ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  size?: 'sm' | 'md' | 'lg';
}

function Tooltip({ content, placement = 'right', children, size }: LocalProps) {
  if (!content) {
    return children;
  }
  return (
    <Tippy
      render={(attrs) => (
        <div className={`g-tooltip ${placement} ${size ? size : ''}`} tabIndex={-1} {...attrs}>
          {content}
          <div className="g-arrow" />
        </div>
      )}
      placement={placement}
      delay={[null, 100]}
    >
      {children}
    </Tippy>
  );
}

export default Tooltip;
