/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastParams } from '../interfaces/Common.interfaces';
import { RootState } from './store';

interface LocalState {
  items?: ToastParams[];
}

const initialState: LocalState = {
  items: [],
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastParams>) => {
      const addedToast = {
        severity: action.payload.severity || 'info',
        summary: action.payload.summary,
        detail: action.payload.detail,
        sticky: action.payload.sticky || false,
        life: action.payload.life ?? 3000,
      };
      state.items?.push(addedToast);
    },
    removeToast: (state) => {
      state.items?.splice(0, 1);
    },
  },
});

export const { addToast, removeToast } = toastSlice.actions;

export const getToast = (state: RootState) => state.toast.items;

export default toastSlice.reducer;
