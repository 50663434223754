/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
class TOSNotAcceptedError implements Error {
  name = 'Terms Of Service';
  message = 'Terms Of service not accepted';
}

export default TOSNotAcceptedError;
