/**
 *
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserMyDisplays, CognitoUser } from '../interfaces/User.interfaces';
import { RootState } from './store';

const initialState: User = {
  email: '',
  name: '',
  firstName: '',
  lastName: '',
  orgBasic: '',
  id: '',
  isAuthenticated: true,
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setCurrentMyDisplay: (state, action: PayloadAction<UserMyDisplays>) => {
      state.defaultDisplay = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUserAttributes: (state, action: PayloadAction<CognitoUser>) => {
      state.id = action.payload.id;
      state.email = action.payload.attributes.email;
      state.firstName = action.payload.attributes.given_name;
      state.lastName = action.payload.attributes.family_name;
      state.orgBasic = action.payload.attributes['custom:business'];
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.orgBasic = action.payload.orgBasic;
      state.id = action.payload.id;
    },
    updateMyProfile: (state, action: PayloadAction<{ firstName: string; lastName: string; orgBasic: string }>) => {
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        orgBasic: action.payload.orgBasic,
      };
    },
    clearUser: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setUser, setIsAuthenticated, setUserAttributes, clearUser, setCurrentMyDisplay, updateMyProfile } =
  userSlice.actions;
export const getUser = (state: RootState) => state.user;
export const getIsAuthenticated = (state: RootState) => state.user.isAuthenticated;

export default userSlice.reducer;
