/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToast, removeToast } from '../../../core/redux/toast.slice';
import ToastMessage from '../Reusable/ToastMessage/ToastMessage';
import './GlobalToast.scss';

const GlobalToast = () => {
  const toasts = useSelector(getToast);
  const dispatch = useDispatch();
  const toastRef = useRef<Toast>(null);

  useEffect(() => {
    if (toastRef.current) {
      const ref = toastRef.current;
      toasts?.forEach((toast) => {
        const { severity, detail, sticky, life } = toast;
        ref.show({
          content: <ToastMessage {...toast} detail={detail?.toString()} />,
          severity,
          sticky,
          life,
        });
        dispatch(removeToast()); // clean the slices right after display
      });
    }
  }, [toasts, dispatch]);
  return (
    <div className="global-toast-container">
      <Toast ref={toastRef} />
    </div>
  );
};

export default GlobalToast;
