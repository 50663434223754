/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

// No exported types for georaster
//@ts-ignore
import L, { LatLngBounds } from 'leaflet';
import { useContext, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { MIN_PLUME_RASTER_ZOOM_LEVEL } from '../constants/Constants';
import { LEAFLET_GROUP_LAYERS, LEAFLET_LAYERS } from '../constants/Enums';
import { AppContext } from '../context/app.context';
import { getNews } from '../redux/news.slice';
import { getGeoTiff, loadPlumeRaster, removePlumeRasters } from '../utils/Layers.utils';
import { getArticleIdFromLayer, getNewsPlumeRasterId } from '../utils/Manifest.utils';
import useLayer from './useLayer';

const refreshReducer = (num: number): number => (num + 1) % 1_000_000;

function useMapExtentWatcher() {
  const { mapView, newsRasterFiles, addNewsRasterFile } = useContext(AppContext);
  const news = useSelector(getNews);
  const layer = useLayer(LEAFLET_GROUP_LAYERS.NewsPlumes);
  const [, refreshLayer] = useReducer(refreshReducer, 0);

  useEffect(() => {
    const handleLeafletEvent = async (event: L.LeafletEvent) => {
      if (layer !== undefined && layer.layers.length > 0 && news.length > 0) {
        const zoom: number = event.target.getZoom();
        if (zoom < MIN_PLUME_RASTER_ZOOM_LEVEL) {
          const rasterLayersExist = layer.layers.some((l) => l.layerId === LEAFLET_LAYERS.NewsPlumesRaster);
          if (rasterLayersExist) {
            removePlumeRasters(layer);
            refreshLayer();
          }
          return null;
        }
        let layerUpdated = false;
        const bounds: LatLngBounds = event.target.getBounds();
        for (const child of layer.layers) {
          if (!(child.layer instanceof L.Marker) || !bounds.contains(child.layer.getLatLng())) {
            continue;
          }

          const id = getArticleIdFromLayer(child);
          const article = news.find((article) => article.id === id);
          if (!article) {
            continue;
          }

          const newLayerId = getNewsPlumeRasterId(article.id);
          const layerExists = layer.layers.some((layer) => layer.id === newLayerId);
          if (layerExists) {
            continue;
          }
          const geoTiff = await getGeoTiff(newsRasterFiles, article.plumeUrl, addNewsRasterFile);
          await loadPlumeRaster(layer, geoTiff, article.id);
          layerUpdated = true;
        }

        if (layerUpdated) {
          refreshLayer();
        }
      }
    };
    if (mapView) {
      mapView.on('zoomend', handleLeafletEvent);
      mapView.on('moveend', handleLeafletEvent);
    }

    return () => {
      if (mapView) {
        mapView.off('zoomend', handleLeafletEvent);
        mapView.off('moveend', handleLeafletEvent);
      }
    };
  }, [mapView, layer, news, newsRasterFiles, addNewsRasterFile]);
}

export default useMapExtentWatcher;
