/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { GroupLayer } from '../classes/GroupLayer';
import { LEAFLET_GROUP_LAYERS } from '../constants/Enums';
import { SpectraDataLayer } from '../interfaces/Common.interfaces';

export function createGroupLayer(id: LEAFLET_GROUP_LAYERS, childLayers?: SpectraDataLayer[]): GroupLayer {
  if (id === LEAFLET_GROUP_LAYERS.Concentration) {
    return getConcentrationGroupLayer();
  } else if (id === LEAFLET_GROUP_LAYERS.NewsPlumes) {
    return getNewsPlumesGroupLayer(childLayers);
  } else if (id === LEAFLET_GROUP_LAYERS.Basemap) {
    return getBasemapGroupLayer(childLayers);
  } else {
    throw new TypeError(`Not a valid Group Layer type (${id})`);
  }
}

function getConcentrationGroupLayer() {
  return new GroupLayer({
    id: LEAFLET_GROUP_LAYERS.Concentration,
  });
}

function getNewsPlumesGroupLayer(childLayers?: SpectraDataLayer[]) {
  return new GroupLayer({
    id: LEAFLET_GROUP_LAYERS.NewsPlumes,
    layers: childLayers,
  });
}

function getBasemapGroupLayer(childLayers?: SpectraDataLayer[]) {
  return new GroupLayer({
    id: LEAFLET_GROUP_LAYERS.Basemap,
    layers: childLayers,
  });
}
