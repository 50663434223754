/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { ReactNode, useEffect, useRef } from 'react';
import { ToastParams } from '../../../../core/interfaces/Common.interfaces';
import { ReactComponent as CancelSvg } from '../../../assets/icons/cancel-cross.svg';
import ToastMessage from '../ToastMessage/ToastMessage';
import './ModalWindow.scss';

interface Props {
  title: string;
  className?: string;
  style?: React.CSSProperties;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  open: boolean;
  children?: ReactNode;
  buttonText?: string;
  onCancelClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disableBtn?: boolean;
  toast?: ToastParams;
  clearToast?: () => void;
}

const ModalWindow = ({
  title,
  style,
  className,
  size,
  open,
  children,
  buttonText,
  onCancelClick,
  onButtonClick,
  toast,
  clearToast,
  disableBtn = false,
}: Props) => {
  const toastRef = useRef<Toast>(null);

  let width: string | undefined = undefined;
  if (size === 'sm') {
    width = '480px';
  } else if (size === 'md') {
    width = '750px';
  } else if (size === 'lg') {
    width = '950px';
  } else if (size === 'xl') {
    width = '1200px';
  }

  if (style) {
    if (!style.width) {
      style.width = width;
    }
  } else if (width) {
    style = {
      width: width,
    };
  }

  useEffect(() => {
    if (toast && toastRef.current) {
      toastRef.current.show({
        content: <ToastMessage {...toast} detail={toast?.detail?.toString()} />,
        severity: toast?.severity,
        life: toast?.life,
        sticky: toast?.sticky,
        closable: true,
      });
    }
  }, [toast]);

  return (
    <Dialog
      className={`g-modal-window ${className ? className : ''}`}
      header={
        <div className="g-dialog-header">
          <div>{title}</div>
          {buttonText && (
            <Button className="p-button-outlined" onClick={onButtonClick} disabled={disableBtn}>
              {buttonText}
            </Button>
          )}
          {onCancelClick && (
            <Button className="cancel-click" onClick={onCancelClick} disabled={disableBtn}>
              <CancelSvg />
            </Button>
          )}
        </div>
      }
      visible={open}
      style={style}
      draggable={false}
      resizable={false}
      onHide={() => null} // required prop but we hide the close 'x' anyway
    >
      <div className="modal-toast-container">
        <Toast ref={toastRef} onHide={clearToast} />
      </div>
      <div className="modal-content-container">{children}</div>
    </Dialog>
  );
};

export default ModalWindow;
