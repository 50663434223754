/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { Auth } from 'aws-amplify';
import deleteAllCookies from '../core/cookies/cookies';
import { AWS_COOKIE_PREFIX } from '../core/constants/Constants';
import Cookies from 'js-cookie';

export const getUserAttributes = () => {
  return Auth.currentUserInfo();
};

export const signOut = () => {
  return Auth.signOut({ global: true });
};

export const transferSubDomainCookiesToCurrentDomain = () => {
  const cookies = document.cookie.split('; ');
  const keepLoggedIn_cookie = cookies.find((c) => c.indexOf('keep_logged_in') > -1);
  let keepLoggedIn = false;
  if (keepLoggedIn_cookie) {
    // eslint-disable-next-line prefer-destructuring
    keepLoggedIn = keepLoggedIn_cookie.split('=')[1] === 'true';
  }
  cookies.forEach((c) => {
    if (c) {
      Cookies.set(AWS_COOKIE_PREFIX + c.split('=')[0].split('spectra-aws')[1], c.split('=')[1], {
        sameSite: 'Strict',
        secure: true,
        expires: keepLoggedIn ? 14 : undefined,
      });
    }
  });
  deleteAllCookies('spectra-aws');
};
