/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Locales } from '../../../../core/language/Localize';
import { addToast } from '../../../../core/redux/toast.slice';
import { isPasswordComplexEnough } from '../../../../core/utils/User.utils';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import ModalFooterButtons from '../../Reusable/ModalWindow/ModalFooterButtons';
import PasswordHint from '../../Reusable/PasswordHint/PasswordHint';
import TextButton from '../../Reusable/TextButton/TextButton';
import TextField, { AUTOCOMPLETE } from '../../Reusable/TextField/TextField';
import Tooltip from '../../Reusable/Tooltip/Tooltip';
import './MyProfilePassword.scss';

enum ERRORS {
  INCORRECT_PASSWORD = 'Invalid username or password',
  PASSWORD_MATCH = 'PASSWORD_MATCH',
  COMPLEXITY = 'COMPLEXITY',
}

enum FIELDS {
  CurrentPassword,
  Password1,
  Password2,
}

interface LocalProps {
  userEmail: string;
  onCancelClick: () => void;
  onPasswordSuccess: () => void;
}

const MyProfilePasswordEdit = ({ userEmail, onCancelClick, onPasswordSuccess }: LocalProps) => {
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword1, setNewPassword1] = useState<string>('');
  const [newPassword2, setNewPassword2] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<ERRORS | string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const dispatch = useDispatch();

  function onFailedSave(message?: string) {
    setErrorMessage(message ? message : Locales.MyProfileActions.PasswordSaveUnsuccessful);
    dispatch(
      addToast({
        severity: 'error',
        summary: Locales.MyProfileActions.PasswordSaveUnsuccessful,
      }),
    );
  }

  const onSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage('');
    if (newPassword1 === newPassword2) {
      if (isPasswordComplexEnough(newPassword1, userEmail)) {
        setLoading(true);
        // try {
        //   const response = await UserService.resetPasswordMyProfile(
        //     userEmail,
        //     currentPassword,
        //     newPassword1,
        //     newPassword2,
        //   );
        //   if (response.success) {
        //     analyticsClient.pushEvent({
        //       category: EventCategory.MyProfile,
        //       action: EventActions.MyProfile.UpdatePassword,
        //     });
        //     dispatch(
        //       addToast({
        //         severity: 'success',
        //         summary: Locales.MyProfileActions.PasswordSaved,
        //       }),
        //     );
        //     onPasswordSuccess();
        //   } else {
        //     if (response.message === ERRORS.INCORRECT_PASSWORD) {
        //       setErrorPassword(ERRORS.INCORRECT_PASSWORD);
        //     } else {
        //       onFailedSave(response.message);
        //     }
        //   }
        // } catch (errResponse) {
        //   const errMessage = getErrorString(errResponse);
        //   logError(errMessage);
        //   onFailedSave();
        // } finally {
        //   setLoading(false);
        // }
      } else {
        setErrorPassword(ERRORS.COMPLEXITY);
      }
    } else {
      setErrorPassword(ERRORS.PASSWORD_MATCH);
    }
  };

  function handlePasswordErrorDismissal(sender: FIELDS | undefined) {
    if (errorPassword) {
      if (sender !== undefined) {
        if (errorPassword === ERRORS.INCORRECT_PASSWORD && sender === FIELDS.CurrentPassword) {
          setErrorPassword(undefined);
        } else if (errorPassword === ERRORS.COMPLEXITY && sender === FIELDS.Password1) {
          setErrorPassword(undefined);
        } else if (errorPassword === ERRORS.PASSWORD_MATCH && sender === FIELDS.Password2) {
          setErrorPassword(undefined);
        }
      } else {
        setErrorPassword(undefined);
      }
    }
  }

  const handleCurrentPasswordChange = (newValue: string) => {
    handlePasswordErrorDismissal(FIELDS.CurrentPassword);
    setCurrentPassword(newValue);
  };

  const handlePassword1Change = (newValue: string) => {
    handlePasswordErrorDismissal(FIELDS.Password1);
    setNewPassword1(newValue);
  };

  const handlePassword2Change = (newValue: string) => {
    handlePasswordErrorDismissal(FIELDS.Password2);
    setNewPassword2(newValue);
  };

  const isValid = currentPassword !== '' && newPassword1 !== '' && newPassword2 !== '';

  return (
    <form onSubmit={onSave}>
      <div className="password-section">
        <TextField
          id="current-password"
          autocomplete={AUTOCOMPLETE.PASSWORD}
          label={Locales.YourCurrentPassword}
          value={currentPassword}
          onChange={handleCurrentPasswordChange}
          hideInput
          helpText={errorPassword === ERRORS.INCORRECT_PASSWORD ? Locales.Errors.PasswordIncorrect : undefined}
          error={errorPassword === ERRORS.INCORRECT_PASSWORD}
          altered={currentPassword !== ''}
        />
        <TextField
          id="new-password"
          autocomplete={AUTOCOMPLETE.NEW_PASSWORD}
          label={Locales.YourNewPassword}
          value={newPassword1}
          onChange={handlePassword1Change}
          hideInput
          rightIcon={
            <Tooltip size="md" content={<PasswordHint />}>
              <div>
                <InfoIcon className="info-icon" />
              </div>
            </Tooltip>
          }
          helpText={errorPassword === ERRORS.COMPLEXITY ? Locales.Errors.PasswordComplexity : undefined}
          error={errorPassword === ERRORS.COMPLEXITY}
          altered={newPassword1 !== ''}
        />
        <TextField
          id="confirm-new-password"
          autocomplete={AUTOCOMPLETE.NEW_PASSWORD}
          label={Locales.ConfirmYourNewPassword}
          value={newPassword2}
          onChange={handlePassword2Change}
          hideInput
          helpText={errorPassword === ERRORS.PASSWORD_MATCH ? Locales.Errors.PasswordMatch : undefined}
          error={errorPassword === ERRORS.PASSWORD_MATCH}
          altered={newPassword2 !== ''}
        />
        <p className="error-message">{errorMessage}</p>
      </div>
      <ModalFooterButtons>
        <TextButton type="outlined" onClick={onCancelClick}>
          {Locales.Cancel}
        </TextButton>
        <TextButton type="filled" loading={loading} disabled={!isValid || loading} submit>
          {Locales.SaveNewPassword}
        </TextButton>
      </ModalFooterButtons>
    </form>
  );
};

export default MyProfilePasswordEdit;
