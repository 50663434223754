/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import Cookies from 'js-cookie';
import { HubspotFormSubmission } from '../core/interfaces/Common.interfaces';

export const sendCompletedFormToHubspot = async (body: HubspotFormSubmission): Promise<boolean> => {
  const portalId = '22487970';
  const formId = '287428b2-47a5-411d-9020-9a5cd79c5c0d';
  const token = Cookies.get('hubspotutk') ?? null;
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}/`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...body, context: { hutk: token } }),
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    return true;
  } else {
    const error = await response.json();
    let message = 'Uknown error in form submission';
    if (error.message) {
      // eslint-disable-next-line prefer-destructuring
      message = error.message;
      if (error.errors && error.errors.length > 0) {
        const [firstError] = error.errors;
        if (firstError.errorType === 'BLOCKED_EMAIL') {
          // User must use an email that is not free
          return false;
        }
        message += `. ${firstError?.message}`;
      }
    }
    throw Error(message);
  }
};
