/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import React from 'react';
import { BASE_NEWS_URL } from '../../../core/constants/Constants';
import { CMapImage } from '../../../core/interfaces/Common.interfaces';
import ModalBody from '../Reusable/ModalWindow/ModalBody';
import ModalWindow from '../Reusable/ModalWindow/ModalWindow';
import './CMapModal.scss';

interface LocalProps {
  setOpen: (string: undefined | CMapImage) => void;
  imageUrl: string;
  imageTitle: string;
}

const CMapModal = ({ setOpen, imageUrl, imageTitle }: LocalProps) => {
  return (
    <ModalWindow title={imageTitle} size="xl" open={true} onCancelClick={() => setOpen(undefined)}>
      <ModalBody style={{ overflow: 'hidden', height: '80vh' }}>
        <div className="cmap-div">
          <img src={BASE_NEWS_URL + '1400px_' + imageUrl} alt={imageTitle} />
        </div>
      </ModalBody>
    </ModalWindow>
  );
};
export default CMapModal;
