/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import useBasemapSwitcher from './useBasemapSwitcher';
import useLayerPopulater from './useLayerPopulater';
import useMapExtentWatcher from './useMapExtentWatcher';
import useWeeksLayerManager from './useWeeksLayerManager';

/**
 * @description This hook is a central spot for all of the essential functionality and side-effects for layers
 * within the map to function properly. Generally, anything that needs access to the app context or needs to respond
 * to actions on the map will need to be in a hook and that should live here.
 */
function useMainMapFunctionality() {
  useLayerPopulater();
  useWeeksLayerManager();
  useMapExtentWatcher();
  useBasemapSwitcher();
}

export default useMainMapFunctionality;
