/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LEAFLET_BASEMAPS } from '../constants/Enums';
import { RootState } from './store';

interface LocalState {
  basemap: LEAFLET_BASEMAPS;
}

const initialState: LocalState = {
  basemap: LEAFLET_BASEMAPS.Topographic,
};

export const layersSlice = createSlice({
  name: 'layers',
  initialState,
  reducers: {
    setBasemap: (state, action: PayloadAction<LEAFLET_BASEMAPS>) => {
      state.basemap = action.payload;
    },
    clearLayers: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setBasemap, clearLayers } = layersSlice.actions;

export const getBasemap = (state: RootState) => state.layers.basemap;

export default layersSlice.reducer;
