/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { InputText } from 'primereact/inputtext';
import React, { ReactNode } from 'react';
import { Locales } from '../../../../core/language/Localize';
import './TextField.scss';

// See https://devdocs.io/html/attributes/autocomplete for full list
export enum AUTOCOMPLETE {
  NAME = 'name',
  FIRST_NAME = 'given-name',
  LAST_NAME = 'family-name',
  EMAIL = 'email',
  USERNAME = 'username',
  PASSWORD = 'current-password',
  NEW_PASSWORD = 'new-password',
  ORGANIZATION = 'organization',
  PHONE = 'tel',
}

interface LocalProps {
  id: string;
  value: string;
  label?: string;
  type?: React.HTMLInputTypeAttribute;
  name?: string;
  autocomplete?: AUTOCOMPLETE;
  helpText?: string;
  rightIcon?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  noPlaceholder?: boolean;
  hideInput?: boolean;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  valid?: boolean;
  altered?: boolean;
  size?: 'sm' | 'md' | 'max';
  onChange: (newValue: string) => void;
}

const TextField = ({
  id,
  value,
  label,
  type,
  name,
  helpText,
  rightIcon,
  className,
  style,
  placeholder,
  noPlaceholder,
  hideInput,
  required,
  error,
  disabled,
  valid,
  altered,
  size,
  onChange,
}: LocalProps) => {
  return (
    <div
      style={style}
      className={`field g-input-text ${className ? className : ''} ${altered ? 'altered' : ''} ${
        disabled ? 'disabled' : ''
      }  ${valid ? 'valid' : ''} ${error ? 'error' : ''} ${size ? size : ''}`}
    >
      {label && (
        <label id={`${id}-label`} htmlFor={id} className="block g-label">
          {label}
        </label>
      )}
      <InputText
        id={id}
        name={name}
        autoComplete={name}
        type={hideInput ? 'password' : type ? type : 'text'}
        className={`block g-input ${rightIcon ? 'right-icon' : ''}`}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
        placeholder={noPlaceholder ? '' : placeholder ? placeholder : Locales.PleaseEnter}
        required={required}
        aria-required={required ?? false}
        aria-invalid={required && value === ''}
        aria-describedby={`${id}-label`}
      />
      {rightIcon && <span className="g-field-end-icon">{rightIcon}</span>}
      {helpText && (
        <small id={`${id}-help`} className="help-text">
          {helpText}
        </small>
      )}
    </div>
  );
};

export default TextField;
