/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import L from 'leaflet';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MAX_ZOOM_LVL } from '../constants/Constants';
import { LEAFLET_GROUP_LAYERS, LEAFLET_LAYERS } from '../constants/Enums';
import { AppContext } from '../context/app.context';
import { SpectraDataLayer, Week } from '../interfaces/Common.interfaces';
import { getWeeks, getActiveWeek } from '../redux/weeks.slice';
import { getConcentrationLayerId } from '../utils/Manifest.utils';
import { getLayerPane } from '../utils/Map.utils';
import useLayer from './useLayer';
import usePrevious from './usePrevious';

/**
 * @description This hook takes in the weeks of concentration tiles and manages the adding and removing
 * of the layers depending on which layer is active
 */
function useWeeksLayerManager() {
  const weeks = useSelector(getWeeks);
  const activeWeek = useSelector(getActiveWeek);
  const { updateLayers } = useContext(AppContext);
  const layer = useLayer(LEAFLET_GROUP_LAYERS.Concentration);
  const previousActiveWeek = usePrevious(activeWeek);

  useEffect(() => {
    // avoid double renders due to the layers context changing
    const shouldRerender = layer?.layers.length === 0 || previousActiveWeek !== activeWeek;
    if (
      layer !== undefined &&
      layer.visible &&
      weeks &&
      activeWeek !== undefined &&
      shouldRerender &&
      weeks[activeWeek] !== undefined
    ) {
      if (layer.layers.length > 0) {
        layer.layers.forEach((l) => {
          layer.removeLayer(l);
        });
      }
      const currentWeek = weeks[activeWeek];
      const newLayerUid = getConcentrationLayerId(activeWeek);
      const newConcentrationLayer: SpectraDataLayer = {
        id: newLayerUid,
        layerId: LEAFLET_LAYERS.Concentration,
        pane: getLayerPane(LEAFLET_LAYERS.Concentration),
        layer: L.tileLayer(currentWeek.url, {
          id: newLayerUid,
          tms: true,
          opacity: 0.4,
          attribution: 'GHGSat',
          maxNativeZoom: 7,
          maxZoom: MAX_ZOOM_LVL,
          className: 'concentration-tiles',
          pane: getLayerPane(LEAFLET_LAYERS.Concentration),
        }),
      };
      layer.addLayer(newConcentrationLayer);
    }
  }, [activeWeek, weeks, updateLayers, layer, previousActiveWeek]);
}

export default useWeeksLayerManager;
