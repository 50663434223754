/**
 *
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import Tippy from '@tippyjs/react';
import { isWithinInterval } from 'date-fns';
import { Calendar as PrimereactCalendar, CalendarChangeParams } from 'primereact/calendar';
import { JSXElementConstructor, ReactElement, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getWeeks } from '../../../../core/redux/weeks.slice';
import { getDateWithoutTimezone } from '../../../../core/utils/Date.utils';

interface LocalProps {
  date: Date;
  open: boolean;
  week?: boolean;
  children?: ReactElement<any, string | JSXElementConstructor<any>>;
  className?: string;
  style?: React.CSSProperties;
  minDate?: Date;
  maxDate?: Date;
  onClose: () => void;
  onChange: (dates: Date) => void;
}

const Calendar = ({
  date,
  week: displayAsWeek,
  onChange,
  className,
  open,
  minDate,
  maxDate,
  onClose,
  children,
}: LocalProps) => {
  const weeks = useSelector(getWeeks);
  const calendarRef = useRef<HTMLDivElement>(null);
  const CAL_WIDTH = 500;
  const CAL_HEIGHT = 460;
  const STANDARD_FONT_SIZE = 16;

  const handleCalendarDatesChange = (e: CalendarChangeParams) => {
    const { value } = e as any;
    if (value) {
      if (displayAsWeek) {
        const dateRange = value as (Date | null)[];
        const [newDate] = dateRange;
        if (newDate) {
          onChange(newDate);
        }
      } else {
        const newDate = value as Date;
        if (newDate) {
          onChange(newDate);
        }
      }
    }
  };

  let weekRange: Date[] | undefined;
  if (displayAsWeek && weeks) {
    const week = weeks?.find((week) => {
      return isWithinInterval(date, {
        start: new Date(week.startDate),
        end: new Date(week.endDate),
      });
    });
    if (week) {
      const start = getDateWithoutTimezone(new Date(week.startDate));
      const end = getDateWithoutTimezone(new Date(week.endDate));
      weekRange = [start, end];
    }
  }

  return (
    <>
      <div ref={calendarRef}>{children}</div>
      <Tippy
        placement="top"
        className={`g-calendar ${className ? className : ''}`}
        appendTo={document.body}
        reference={open && calendarRef ? calendarRef : undefined}
        interactive={true}
        visible={open}
        maxWidth={`${CAL_WIDTH / STANDARD_FONT_SIZE}rem`}
        onClickOutside={onClose}
        content={
          date && (
            <div className="g-calendar">
              <PrimereactCalendar
                style={{
                  width: `${CAL_WIDTH / STANDARD_FONT_SIZE}rem`,
                  height: `${CAL_HEIGHT / STANDARD_FONT_SIZE}rem`,
                }}
                inline
                selectionMode={displayAsWeek ? 'range' : undefined}
                value={displayAsWeek ? weekRange : date}
                onChange={handleCalendarDatesChange}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          )
        }
      />
    </>
  );
};

export default Calendar;
