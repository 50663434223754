/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { ReactNode } from 'react';
import './ModalFooterButtons.scss';

interface LocalProps {
  children: ReactNode;
}

const ModalFooterButtons = ({ children }: LocalProps) => {
  return <div className="modal-footer-buttons">{children}</div>;
};

export default ModalFooterButtons;
